import Vue from 'vue';

import { PhoneNumberUtil } from 'google-libphonenumber';

// vee-validate
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, max, confirmed } from 'vee-validate/dist/rules';
extend('required', {
  ...required,
  message: '{_field_} は必須です',
});
extend('max', {
  ...max,
  message: '{_field_} は {length}文字以内にしてください',
});
extend('confirmed', {
  ...confirmed,
  message: '{_field_} は 一致していません',
});

extend('phone', {
  message: ' {_field_} は電話番号のフォーマットにしてください',
  validate(value) {
    const util = PhoneNumberUtil.getInstance();
    try {
      const phoneNumber = util.parseAndKeepRawInput(value, 'JP');
      if (!util.isValidNumber(phoneNumber)) return false;

      // 上記だと全角が通ってしまうため以下を追加する
      // ハイフンを消す
      value = value.replace(/[━.*‐.*―.*－.*-.*ー.*-]/gi, '');

      // コレでもいい
      // const regex = /^(0{1}\d{9,10})$/;
      // console.log('regex', regex.test(value));

      if (!value.match(/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/)) return false;

      return true;
    } catch (err) {
      return false;
    }
  },
});

// 自分が実装したvee-validateのカスタムルールをインポート
// import * as CustomRules from '~/utils/validation-custom-rules.js'
/*
// validation-custom-rules.js

const custom_rule = {
  // ルールを書くときに使う引数。
  params: ['compare'],

  // バリデーションロジック
  validate(field, { compare }) {
    return true // or false true: バリデーションエラーなし, false: バリデーションエラーあり
  },

  // バリデーションエラーメッセージ
  message(field, { compare }){
    return `validation error. ${field}` 
  },
}

export { custom_rule }
*/

// for (const rule in CustomRules) {
//   extend(rule, CustomRules[rule])
// }

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
