import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate';

import * as Cookies from 'js-cookie';

import Mixin from '@/mixins/mixin';

Vue.use(Vuex);

const user = {
  isLogined: false,
  code: null,
  name: null,

  avatar: {
    src: null,
  },
  authorityTypes: [],
  token: null,
};

const api = {
  license: null,
};

const state = {
  user: user,

  api: api,

  isSplash: false,

  isSplashError: false,

  /** 通信中か？ */
  isRequesting: false,

  /** フォーム内容が変更されたか？ */
  formChanged: false,

  /** 通知用スナックバー */
  snackbar: {
    show: false,
    color: 'success',
    message: null,
  },

  toast: {
    message: null,
  },

  view: {
    user: {
      selectedProcess: 0,
    },
    analyse: {
      showGraph: false,
      showTeam: true,
      // 支店の表示順
      branchOrder: [],
      // 非表示にする
      inVisibledBranchs: [],
    },
  },

  /** APIより読み込んだデータ */
  dataContext: {},
};

// import axios from "axios";

export default new Vuex.Store({
  state: state,
  mutations: {
    // isSplash
    setIsSplash(state, isSplash) {
      state.isSplash = isSplash;
    },

    // setIsSplashError
    setIsSplashError(state, isSplashError) {
      state.isSplashError = isSplashError;
    },

    // axiosRequest状態
    setIsRequesting(state, isRequesting) {
      state.isRequesting = isRequesting;
    },

    /** フォーム内容が変更されたか？ */
    setFormChanged(state, formChanged) {
      state.formChanged = formChanged;
    },

    // toast状態
    setToast(state, toast) {
      state.toast = toast;
    },
    // snackbar状態
    setSnackbar(state, snackbar) {
      state.snackbar = snackbar;
    },

    setUser(state, user) {
      //
      let code = null;
      let name = null;
      let email = null;

      let avatar = {
        src: null,
      };
      let authorityTypes = [];
      //
      if (user != null) {
        code = user.code;
        name = user.name;
        email = user.email;
        avatar.src = user.avatar.src;
        authorityTypes = user.authorityTypes;
      }

      state.user.code = code;
      state.user.name = name;
      state.user.email = email;
      state.user.avatar = avatar;
      state.user.authorityTypes = authorityTypes;
    },

    setUserToken(state, token) {
      state.user.isLogined = token != null;
      state.user.token = token;
    },

    clearUser(state) {
      let user = state.user;
      user.code = null;
      user.name = null;
      user.email = null;

      user.avatar.src = null;
      user.authorityTypes = [];
      user.isLogined = false;
      user.token = null;

      //
      state.user = user;
    },

    setApi(state, api) {
      state.api = api;
    },

    // APIより読み込んだデータ
    setDataContext(state, dataContext) {
      state.dataContext = dataContext;
    },

    // 個別の表示設定
    setViewUserSelectedProcess(state, selectedProcess) {
      state.view.user.selectedProcess = selectedProcess;
    },

    setViewAnalyseShowGraph(state, showGraph) {
      state.view.analyse.showGraph = showGraph;
    },

    setViewAnalyseShowTeam(state, showTeam) {
      state.view.analyse.showTeam = showTeam;
    },

    setViewAnalyseBranchOrder(state, branchOrder) {
      state.view.analyse.branchOrder = branchOrder;
    },

    setViewAnalyseInVisibledBranchs(state, inVisibledBranchs) {
      state.view.analyse.inVisibledBranchs = inVisibledBranchs;
    },
  },
  getters: {
    user(state) {
      return state.user;
    },

    token(state) {
      let user = state.user;
      return user != null ? user.token : null;
    },

    userCode(state) {
      let user = state.user;
      return user != null ? user.code : '';
    },

    isSplash(state) {
      return state.isSplash;
    },

    isSplashError(state) {
      return state.isSplashError;
    },

    isRequesting(state) {
      return state.isRequesting;
    },


    isFormChanged(state) {
      return state.formChanged;
    },

    toast(state) {
      return state.toast;
    },
    snackbar(state) {
      return state.snackbar;
    },

    api(state) {
      return state.api;
    },

    dataContext(state) {
      return state.dataContext;
    },

    view(state) {
      console.log('store.getter.view', state.view);

      return state.view;
    },
  },
  actions: {
    async splashApp({ commit }) {
      // スプラッシュ処理開始
      commit('setIsSplash', true);

      try {
        // 設定読込
        {
          let success = await Mixin.methods.get('setting', {});

          // コンテキストをストア保持
          commit('setDataContext', success.data.setting);
          // スプラッシュ処理を完了させる
          commit('setIsSplash', false);
          commit('setIsSplashError', false);
        }
      } catch (e) {
        // // スプラッシュエラーを設定
        commit('setIsSplashError', true);

        // // スナックバーでエラーを表示し、スプラッシュで画面を止める
        commit('setSnackbar', {
          show: true,
          color: 'error',
          message: 'アプリケーションの起動ができませんでした',
        });

        // スプラッシュ処理を完了させる
        commit('setIsSplash', false);

        return Promise.reject(e);
      }
    },
  },
  modules: {},
  // 永続化
  plugins: [
    // Cookiesに保存する
    createPersistedState({
      key: 'libery-management',
      reducer: (state) => ({
        user: state.user,
        view: state.view,
        api: state.api,
      }),
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, { expires: 7, secure: true }), //7日間有効
        removeItem: (key) => Cookies.remove(key),
      },
    }),
    // セッションに保存する
    // createPersistedState({ key: 'libery-management', storage: window.sessionStorage }),
  ],
});
