<template>
  <div class="sample-box-13" :data-item="label">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    types: {
      type: Array,
    },

    label: {
      type: String,
      default: () => 'test',
    },

    value: {
      type: String,
      default: () => '',
    },

    messages: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    color() {
      if (this.messages.length > 0) return 'error';
      return '';
    },
  },
};
</script>

<style>
.sample-box-13 {
  border: 0.8px solid #797979;
  border-radius: 4px;
  margin: 2em 0;
  padding: 2em;
  position: relative;
}
.sample-box-13::before {
  background-color: #fff;
  color: #8a8a8a;
  content: attr(data-item);
  font-weight: bold;
  left: 1em;
  padding: 0 0.5em;
  position: absolute;
  top: -1em;
}
</style>