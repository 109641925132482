<template>
  <span v-if="isZero">-</span>
  <v-progress-linear
    v-else
    class="rounded rounded-l-0"
    :value="value"
    :height="height"
    :color="$options.filters.thermo((100 - value) / 100)"
  >
    <template v-slot:default="{ value }">
      <span class="text-caption white--text">{{ Math.ceil(value * 100) / 100 }}%</span>
    </template>
  </v-progress-linear>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: [Number, String],
      default: () => 0,
    },
    height: {
      type: Number,
      default: () => 16,
    },
  },
  computed: {
    isZero() {
      let value = this.value;
      if (Number.isNaN(value)) return true;
      if (value == 0) return true;
      return false;
    },
  },

  data: () => ({}),

  methods: {
    onLoaded() {},
  },
  watch: {},
  mounted() {
    this.onLoaded();
  },
};
</script>