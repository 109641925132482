// 関数拡張
require('@/prototypes');

import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
//
import Mixin from './mixins/mixin';
Vue.mixin(Mixin);

Vue.config.productionTip = false;

// async対応
import 'babel-polyfill';

// 設定ファイル読み込み
import webconfig from '@/web-configs';

// vue-toasted
import Toasted from 'vue-toasted';
Vue.use(Toasted, {
  position: 'bottom-right',
  duration: 5000, // FIXME: Toasted速度ハードコーディング中
  iconPack: 'mdi',
  icon: 'mdi-alert-circle-outline',
  type: 'info',
  theme: 'outline',
});

// axios
import axios from 'axios'; // axios本体
import VueAxios from 'vue-axios'; // vue内でthis.axiosで使用できるようにさせる
axios.defaults.headers.common['apikey'] = webconfig.api.apikey; // FIXME 暗号化させるように
axios.defaults.baseURL = webconfig.api.baseURL;
axios.interceptors.request.use((config) => {
  console.log('interceptors.request', config);

  /*
  // 今回のAPIはスラッシュがないとリダイレクトされるので・・
  config.url = config.url+"/";

  // FIXME 本来ならHEADERで行うこと
  if (config.method == `get`) {
    if (config.params == undefined) config.params = {};
    config.params.apikey = webconfig.api.apikey
  } else {
    if (config.data == undefined) config.data = new FormData();
    config.data.append("apikey", webconfig.api.apikey);
  }
  */

  // ローディング開始
  setRequest(true);

  return config;
});
axios.interceptors.response.use(
  async (response) => {
    console.log('interceptors.response', response);

    // ローディング完了
    setRequest(false);

    return response;
  },
  async (error) => {
    console.error('interceptors.error', error);
    //

    if (error.response != null) {
      switch (error.response.status) {
        case 400:
          {
            console.error('interceptors.error [400]', error);

            store.commit('setSnackbar', {
              message: '入力内容に誤りがあります',
              show: true,
              color: 'warning',
            });
          }
          break;

        case 500:
          {
            //
            let code = error?.response?.data?.code;
            let message = error?.response?.data?.json?.message;
            if(!code )code = "-"
            if(!message )message = "不明なエラーが発生しました"

            store.commit('setSnackbar', {
              message: `[${code}] ${message}`,
              show: true,
              color: 'warning',
            });
          }
          break;
      }
    } else {
      //
      store.commit('setSnackbar', {
        message: 'エラーが発生しました',
        show: true,
        color: 'warning',
      });
    }

    // ローディング完了
    setRequest(false);

    // これを返さなければ例外を処理したとみなされる
    // 呼び出し側でCatchで処理しなければUncaughtとなるが、それはに呼び出し側に任せる
    return Promise.reject(error);
  }
);
Vue.use(VueAxios, axios);

// vue-moment
// const moment = require('moment')
// Vue.use(require('vue-moment'), {
//   moment
// })
// moment.locale('ja')

const moment = require('moment-timezone');
moment.tz.setDefault('Asia/Tokyo');
Vue.use(require('vue-moment'), {
  moment,
});

// vuetify-confirm
import VuetifyConfirm from 'vuetify-confirm';
Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: 'はい',
  buttonFalseText: 'いいえ',
});

// vue-splash
import VueSplash from 'vue-splash';
Vue.use(VueSplash);

// vue2-google-maps
import * as GoogleMaps from 'vue2-google-maps';
Vue.use(GoogleMaps, {
  load: webconfig.googlemap,
});

//vue-cookies
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);
Vue.$cookies.config('7d');

// https://dev.classmethod.jp/articles/try-nuxt-ts-vuetify-vuedraggable/
// vuedraggable
import Draggable from 'vuedraggable';
Vue.component('Draggable', Draggable);

// vee-validate
require('./main-validate');

//
import vBlur from 'v-blur';
Vue.use(vBlur);

//
import Password from 'vue-password-strength-meter';
Vue.component('password', Password);

// 自作フィルタ
import * as filters from '@/filters';
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

// 自作コンポーネント

import XCheckLabel from '@/components/UIComponents/CheckLabel';
Vue.component('XCheckLabel', XCheckLabel);

import XFieldSet from '@/components/UIComponents/FieldSet';
Vue.component('XFieldSet', XFieldSet);

import XLoader from '@/components/UIComponents/Loader';
Vue.component('XLoader', XLoader);

import XMessage from '@/components/UIComponents/Message';
Vue.component('XMessage', XMessage);

import XChipDummy from '@/components/UIComponents/chips/Dummy';
Vue.component('XChipDummy', XChipDummy);
import XChipMissed from '@/components/UIComponents/chips/Missed';
Vue.component('XChipMissed', XChipMissed);
import XChipCancelled from '@/components/UIComponents/chips/Cancelled';
Vue.component('XChipCancelled', XChipCancelled);

import XChipModified from '@/components/UIComponents/chips/Modified';
Vue.component('XChipModified', XChipModified);

import XProgressBar from '@/components/UIComponents/ProgressBar';
Vue.component('XProgressBar', XProgressBar);

import XSheet from '@/components/wireframes/Sheet';
Vue.component('XSheet', XSheet);

// dialogs
import DateTime from '@/components/UIComponents/dateTimes/DateTime';
Vue.component('DateTime', DateTime);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

//#region  main.js 内のプライベート関数

/**リクエスト中であるかを設定*/
function setRequest(isLoading) {
  store.commit('setIsRequesting', isLoading);
}

//#endregion
