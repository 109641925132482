<template>
  <v-card flat>
    <v-card-title>
      <slot name="close"></slot>
      <v-spacer />
      {{ formTitle }}

      <v-spacer />
      <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onCommit">登録</v-btn>
    </v-card-title>

    <v-card-text>
      <XSheet :loading="initializing">
        <template v-slot="{ loaded }">
          <div v-if="loaded">
            <template v-if="apo">
              <ApoResult :apo="apo" />
              <v-divider />
            </template>

            <template v-if="demo">
              <DemoResult :demo="demo" />
              <v-divider />
            </template>

            <LeaseResult :leasePayment="creator" />
            <v-divider />

            <v-expansion-panels v-model="expansions">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.leaseCompanyType)"
                      >{{ isEmptyValue(creator.leaseCompanyType) ? 'リース審査結果を登録してください' : 'リース審査結果' }}

                      <span v-if="creator.state != null" class="success--text">
                        <v-chip :color="creator.state == LeasePaymentStates.OK ? 'success' : 'warning'" small>
                          {{ creator.state | leasePaymentState }}
                        </v-chip>
                        <span>{{ creator.leaseCompanyType | leasePaymentCompanyType }}</span>
                        <span class="ml-2">審査結果日: {{ creator.examinationResultDate | date }}</span>
                      </span>
                    </XCheckLabel>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card flat>
                    <v-card-text>
                      <v-alert border="left" colored-border type="info" outlined>報告対象とする審査結果にチェックを付けてください </v-alert>
                      <v-expansion-panels accordion>
                        <v-expansion-panel v-for="(result, i) in examinationResult.results" :key="i">
                          <v-expansion-panel-header>
                            <div>
                              <v-checkbox
                                v-model="result.report"
                                dense
                                @click.stop="
                                  examinationResult.results
                                    .filter((a) => a.leaseCompanyType != result.leaseCompanyType)
                                    .forEach((a) => (a.report = false))
                                "
                              >
                                <template v-slot:label>
                                  <!-- OK/NGのみ出す -->
                                  <v-chip
                                    v-if="[LeasePaymentStates.OK, LeasePaymentStates.NG].includes(result.state)"
                                    :color="result.state == LeasePaymentStates.OK ? 'success' : 'warning'"
                                    small
                                  >
                                    {{ result.state | leasePaymentState }}
                                  </v-chip>
                                  {{ result.leaseCompanyType | leasePaymentCompanyType }}

                                  <span class="text-caption">
                                    <!-- <span v-if="result.examinationDate != null">提出: {{ result.examinationDate | date }}</span> -->
                                    <span v-if="result.examinationResultDate != null"
                                      >結果日: {{ result.examinationResultDate | date }}</span
                                    >
                                  </span>
                                </template>
                              </v-checkbox>
                            </div>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-card flat>
                              <v-card-text>
                                <!-- <XFieldSet class="mb-4" label="リース会社審査提出日">
                                  <DateTime label="審査提出日" v-model="result.examinationDate" hideTime>
                                    <template v-slot="{ click }"
                                      ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on"
                                        >{{ result.examinationDate | date }}
                                      </v-btn>
                                    </template>
                                  </DateTime>
                                </XFieldSet> -->

                                <XFieldSet class="mb-4" label="審査結果日">
                                  <DateTime label="審査結果日" v-model="result.examinationResultDate" hideTime>
                                    <template v-slot="{ click }"
                                      ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on"
                                        >{{ result.examinationResultDate | date }}
                                      </v-btn>
                                    </template>
                                  </DateTime>
                                </XFieldSet>

                                <v-select
                                  v-model="result.state"
                                  :items="[
                                    {
                                      text: $options.filters.leasePaymentState(LeasePaymentStates.Default),
                                      value: LeasePaymentStates.Default,
                                    },
                                    { text: $options.filters.leasePaymentState(LeasePaymentStates.OK), value: LeasePaymentStates.OK },
                                    { text: $options.filters.leasePaymentState(LeasePaymentStates.NG), value: LeasePaymentStates.NG },
                                  ]"
                                  label="*審査結果"
                                  :rules="[(v) => !!v || '審査結果は必須です']"
                                  required
                                  outlined
                                  dense
                                />

                                <v-textarea outlined v-model="result.memo" label="備考" counter :maxlength="1000" clearable />
                              </v-card-text>
                            </v-card>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>

                      <!-- 
                        <validation-provider v-slot="{ errors }" name="リース会社" rules="required">
                          <v-select
                            v-model="leaseCompanyType"
                            :items="[
                              { text: $options.filters.leasePaymentCompanyType(1), value: 1 },
                              { text: $options.filters.leasePaymentCompanyType(1 << 1), value: 1 << 1 },
                              { text: $options.filters.leasePaymentCompanyType(1 << 2), value: 1 << 2 },
                              { text: $options.filters.leasePaymentCompanyType(1 << 5), value: 1 << 5 },
                            ]"
                            label="*リース会社"
                            :rules="[(v) => !!v || 'リース会社 は必須です']"
                            :error-messages="errors"
                            required
                            outlined
                            dense
                          />
                        </validation-provider> -->
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        text
                        color="warning"
                        @click="
                          leaseCompanyType = null;
                          creator.leaseCompanyType = null;

                          initExaminationResult();
                        "
                        >クリア
                      </v-btn>
                      <v-spacer />
                      <v-btn
                        color="primary"
                        @click="
                          // creator.leaseCompanyType = leaseCompanyType;

                          // NGではなく、報告対象としているもの
                          let result = examinationResult.results.find((a) => a.report);
                          if (result != null) {
                            creator.leaseCompanyType = result.leaseCompanyType;
                            creator.examinationResultDate = result.examinationResultDate;
                            creator.state = result.state;
                          } else {
                            creator.leaseCompanyType = null;
                            creator.examinationResultDate = null;
                            creator.state = null;
                          }
                          //
                          creator.examinationResult = examinationResult;

                          //

                          expansions++;
                        "
                        >設定
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.examinationResultDate) && !isEmptyValue(creator.state)">{{
                      isEmptyValue(creator.examinationResultDate) || isEmptyValue(creator.state)
                        ? '*審査結果日,審査結果を登録してください'
                        : '*審査結果日,審査結果'
                    }}</XCheckLabel>
                    <span
                      v-if="creator.examinationResultDate != null"
                      class="ml-4 success--text"
                      v-text="$options.filters.date(creator.examinationResultDate)"
                    ></span>
                  </div>
                  <div>
                    <span class="ml-4 success--text" v-text="$options.filters.leasePaymentState(creator.state)"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <XFieldSet class="mb-4" label="審査結果日">
                          <validation-provider v-slot="{ errors }" name="審査結果日" rules="required">
                            <DateTime label="審査結果日" v-model="examinationResultDate" hideTime :error-messages="errors">
                              <template v-slot="{ click }"
                                ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on"
                                  >{{ examinationResultDate | date }}
                                </v-btn>
                              </template>
                            </DateTime>
                          </validation-provider>
                        </XFieldSet>

                        <validation-provider v-slot="{ errors }" name="審査結果" rules="required">
                          <v-select
                            v-model="state"
                            :items="[
                              { text: $options.filters.leasePaymentState(1 << 2), value: 1 << 2 },
                              { text: $options.filters.leasePaymentState(1 << 3), value: 1 << 3 },
                            ]"
                            label="*審査結果"
                            :rules="[(v) => !!v || '審査結果は必須です']"
                            :error-messages="errors"
                            required
                            outlined
                            dense
                            @change="
                              {
                                // FIXME Reportにも同じ処理があるため統合させること
                                let message = meta.ngMessage;
                                if (message == null) message = ``;
                                if (state == 1 << 3) {
                                  message += `${$options.filters.date(new Date())}:`;
                                  message += `${$options.filters.leasePaymentCompanyType(leasePayment.leaseCompanyType)}`;
                                  message += ` NG`;
                                }
                                meta.ngMessage = message;
                              }
                            "
                          />
                        </validation-provider>

                        <validation-provider v-slot="{ errors }" name="NG理由" rules="max:1000">
                          <v-textarea
                            v-if="state == 1 << 3"
                            outlined
                            v-model="meta.ngMessage"
                            label="NG理由"
                            counter
                            :maxlength="1000"
                            :error-messages="errors"
                            clearable
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            examinationResultDate = null;
                            state = null;
                            meta.ngMessage = null;

                            creator.examinationResultDate = null;
                            creator.state = null;
                            creator.meta.ngMessage = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.examinationResultDate = examinationResultDate;
                            creator.state = state;
                            creator.meta.ngMessage = meta.ngMessage;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel> -->

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.rate)">
                      <!-- 必須かをOKか否かで切り替え -->
                      <span v-if="creator.state == LeasePaymentStates.OK">*</span>
                      <span>{{ isEmptyValue(creator.rate) ? '料率を登録してください' : '料率' }}</span>
                    </XCheckLabel>

                    <span v-if="creator.rate != null" class="ml-4 success--text">{{ creator.rate }}&#37;</span>

                    <span v-if="demo != null" class="ml-4 success--text">
                      売上見込み: {{ $options.filters.calcLeaseAmount(demo.sales, demo.saleType, creator.rate, demo.term) | priceJP }}
                    </span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <!-- 必須かをOKか否かで切り替え -->
                      <v-card-text>
                        <validation-provider
                          v-slot="{ errors }"
                          name="料率"
                          :rules="creator.state == LeasePaymentStates.OK ? 'required|max:100' : 'max:100'"
                        >
                          <v-combobox
                            v-model.number="rate"
                            :items="['1.83', '1.82']"
                            label="料率"
                            :hint="`現在の売上見込み金額は ${$options.filters.priceJP(demo.prospectAmount)} です`"
                            persistent-hint
                            counter
                            :maxlength="5"
                            :error-messages="errors"
                            dense
                          ></v-combobox>
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            rate = null;
                            creator.rate = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.rate = rate;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <XCheckLabel :value="!isEmptyValue(creator.residualBonds)">{{
                    isEmptyValue(creator.residualBonds) ? '残債、他社買取、サービス品を登録してください' : '残債、他社買取、サービス品'
                  }}</XCheckLabel>

                  <span v-if="creator.residualBonds != null" class="ml-4 success--text">残債:{{ creator.residualBonds | priceJP }}</span>
                  <span v-if="creator.underwritingBonds != null" class="ml-4 success--text"
                    >他社買取:{{ creator.underwritingBonds | priceJP }}
                  </span>
                  <span v-if="creator.complimentaryBonds != null" class="ml-4 success--text"
                    >サービス品:{{ creator.complimentaryBonds | priceJP }}
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="残債" rules="max:100">
                          <v-text-field
                            class="ml-2"
                            type="number"
                            v-model.number="residualBonds"
                            label="残債"
                            counter
                            :maxlength="10"
                            :error-messages="errors"
                            clearable
                            dense
                            suffix="円"
                          />
                        </validation-provider>
                        <validation-provider v-slot="{ errors }" name="他社買取" rules="max:100">
                          <v-text-field
                            class="ml-2"
                            type="number"
                            v-model.number="underwritingBonds"
                            label="他社買取"
                            counter
                            :maxlength="10"
                            :error-messages="errors"
                            clearable
                            dense
                            suffix="円"
                          />
                        </validation-provider>
                        <validation-provider v-slot="{ errors }" name="サービス品" rules="max:100">
                          <v-text-field
                            class="ml-2"
                            type="number"
                            v-model.number="complimentaryBonds"
                            label="サービス品"
                            counter
                            :maxlength="10"
                            :error-messages="errors"
                            clearable
                            dense
                            suffix="円"
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            residualBonds = null;
                            underwritingBonds = null;
                            complimentaryBonds = null;
                            creator.residualBonds = null;
                            creator.underwritingBonds = null;
                            creator.complimentaryBonds = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.residualBonds = residualBonds;
                            creator.underwritingBonds = underwritingBonds;
                            creator.complimentaryBonds = complimentaryBonds;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- 備考をはさんで上記にも同じ条件のブロックがあることに注意 -->
              <template v-if="!isNG && hasViewStartState">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <XCheckLabel :value="!isEmptyValue(creator.startDate)">リース開始確認日</XCheckLabel>
                    <span>{{ creator.startDate | date }}</span>

                    <XCheckLabel :value="!isEmptyValue(creator.completeScheduleDate)">入金予定日</XCheckLabel>
                    <span>{{ creator.completeScheduleDate | date }}</span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ValidationObserver v-slot="observer2">
                      <v-card flat>
                        <v-card-text>
                          <XFieldSet label="リース開始確認日">
                            <validation-provider v-slot="{ errors }" name="リース開始確認日" rules="">
                              <DateTime label="リース開始確認日" v-model="startDate" hideTime :error-messages="errors">
                                <template v-slot="{ click }"
                                  ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on">{{ startDate | date }} </v-btn>
                                </template>
                              </DateTime>
                            </validation-provider>
                          </XFieldSet>

                          <XFieldSet label="入金予定日">
                            <validation-provider v-slot="{ errors }" name="入金予定日" rules="">
                              <DateTime label="入金予定日" v-model="completeScheduleDate" hideTime :error-messages="errors">
                                <template v-slot="{ click }"
                                  ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on"
                                    >{{ completeScheduleDate | date }}
                                  </v-btn>
                                </template>
                              </DateTime>
                            </validation-provider>
                          </XFieldSet>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn
                            text
                            color="warning"
                            @click="
                              startDate = null;
                              completeScheduleDate = null;

                              creator.startDate = null;
                              creator.completeScheduleDate = null;
                            "
                            >クリア
                          </v-btn>
                          <v-spacer />
                          <v-btn
                            color="primary"
                            :disabled="observer2.invalid"
                            @click="
                              creator.startDate = startDate;
                              creator.completeScheduleDate = completeScheduleDate;

                              expansions++;
                            "
                            >設定
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </ValidationObserver>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <template v-if="!isVViewStartStateNotStart">
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <XCheckLabel :value="!isEmptyValue(creator.completeDate)">入金確認</XCheckLabel>
                      <span>{{ creator.completeDate | date }}</span>
                      <span class="ml-4">{{ creator.amount | priceJP }}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <ValidationObserver v-slot="observer2">
                        <v-card flat>
                          <v-card-text>
                            <XFieldSet label="入金確認日">
                              <validation-provider v-slot="{ errors }" name="入金確認日" rules="">
                                <DateTime label="入金確認日" v-model="completeDate" hideTime :error-messages="errors">
                                  <template v-slot="{ click }"
                                    ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on"
                                      >{{ completeDate | date }}
                                    </v-btn>
                                  </template>
                                </DateTime>
                              </validation-provider>
                            </XFieldSet>

                            <v-card outlined>
                              <v-card-title>入金金額</v-card-title>
                              <v-card-text>
                                <v-sheet max-width="500">
                                  <v-text-field
                                    class="mt-10"
                                    type="number"
                                    v-model.number="tmpAmount"
                                    label="金額(税込み)"
                                    hint="確定金額(税抜)を税込み入力自動計算用(入力内容は保持されません)"
                                    persistent-hint
                                    counter
                                    :maxlength="10"
                                    clearable
                                    dense
                                    suffix="円"
                                    @input="amount = Math.round((tmpAmount / (1 + tmpTax)) * 10) / 10"
                                  >
                                    <template v-slot:append-outer>
                                      <v-select
                                        class="ml-2"
                                        v-model.number="tmpTax"
                                        :items="[{ text: '10%', value: 0.1 }]"
                                        label="*消費税"
                                        :maxlength="5"
                                        outlined
                                        dense
                                      ></v-select>
                                    </template>
                                  </v-text-field>
                                </v-sheet>

                                <validation-provider v-slot="{ errors }" name="確定金額" rules="max:100">
                                  <v-text-field
                                    class="mt-10"
                                    type="number"
                                    v-model.number="amount"
                                    label="確定金額(税抜)"
                                    counter
                                    :maxlength="10"
                                    :error-messages="errors"
                                    clearable
                                    dense
                                    suffix="円"
                                  />
                                </validation-provider>
                              </v-card-text>
                            </v-card>
                            <p>
                              入金確認と入金NGを両方入力した場合、<strong class="red--text">入金確認処理が優先</strong>されます。<br />
                              入金確認で登録後、入金NGに変更する場合、入金確認日を<span class="warning--text">クリア</span
                              >で空にしてください
                            </p>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn
                              text
                              color="warning"
                              @click="
                                tmpAmount = null;
                                tmpTax = 0.1;

                                completeDate = null;
                                amount = null;
                                creator.completeDate = null;
                                creator.amount = null;
                              "
                              >クリア
                            </v-btn>
                            <v-spacer />
                            <v-btn
                              color="primary"
                              :disabled="observer2.invalid"
                              @click="
                                creator.completeDate = completeDate;
                                creator.amount = amount;
                                expansions++;
                              "
                              >設定
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </ValidationObserver>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </template>
              </template>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <XCheckLabel :value="!isEmptyValue(creator.memo)">{{
                    isEmptyValue(creator.memo) ? '備考を登録してください' : '備考'
                  }}</XCheckLabel>

                  <span class="d-inline-block text-truncate ml-2" style="max-width: 120px">{{ creator.memo }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="備考" rules="max:1000">
                          <v-textarea v-model="memo" label="備考" counter :maxlength="1000" :error-messages="errors" clearable outlined />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            memo = null;
                            creator.memo = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.memo = memo;
                            expansions = null;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- 備考をはさんで上記にも同じ条件のブロックがあることに注意 -->
              <template v-if="!isNG && hasViewStartState">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div>
                      <XCheckLabel :value="!isEmptyValue(creator.ngDate)">入金NG確認</XCheckLabel>
                      <span>{{ creator.ngDate | date }}</span>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ValidationObserver v-slot="observer2">
                      <v-card flat>
                        <v-card-text>
                          <XFieldSet label="入金NG確認日">
                            <validation-provider v-slot="{ errors }" name="入金NG確認日" rules="">
                              <DateTime label="入金NG確認日" v-model="ngDate" hideTime :error-messages="errors">
                                <template v-slot="{ click }"
                                  ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on">{{ ngDate | date }} </v-btn>
                                </template>
                              </DateTime>
                            </validation-provider>
                          </XFieldSet>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn
                            text
                            color="warning"
                            @click="
                              ngDate = null;
                              creator.ngDate = null;
                            "
                            >クリア
                          </v-btn>
                          <v-spacer />
                          <v-btn
                            color="primary"
                            :disabled="observer2.invalid"
                            @click="
                              creator.ngDate = ngDate;
                              expansions++;
                            "
                            >設定
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </ValidationObserver>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </template>
            </v-expansion-panels>
          </div>
        </template>
      </XSheet>
    </v-card-text>
    <v-card-actions>
      <span class="text-caption">{{ leasePaymentCode }}</span>
    </v-card-actions>
  </v-card>
</template>

<script>
import ApoResult from '@/components/apos/Result';
import DemoResult from '@/components/demos/Result';
import LeaseResult from './Result';

import { LeasePaymentStartStates, LeasePaymentCompanyTypes, LeasePaymentStates } from '@/types';

export default {
  name: 'LeasePaymentEdit',
  components: {
    ApoResult,
    DemoResult,
    LeaseResult,
  },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },

    observer: {
      type: Object,
      required: true,
    },

    leasePaymentCode: {
      type: String,
      required: true,
    },

    viewStartState: {
      type: Number,
      default: () => 0,
    },
  },
  computed: {
    LeasePaymentStates() {
      return LeasePaymentStates;
    },
    LeasePaymentCompanyTypes() {
      return LeasePaymentCompanyTypes;
    },
    LeasePaymentStartStates() {
      return LeasePaymentStartStates;
    },
    form() {
      return {
        loading: () => this.creator.loading,
        disabled: () => this.creator.loading || this.initializing,
        canSubmit: () => !this.creator.loading && !this.initializing && !this.observer.invalid && this.canCommit,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
    facilities() {
      return [];
    },
    canCommit() {
      /*
      if (this.isEmptyValue(this.creator.leaseCompanyType)) return false;
      // いずれのリース会社にも報告対象チェックが入っていない
      if (this.creator.examinationResult.results.filter((a) => a.report).length == 0) return false;
      if (this.isEmptyValue(this.creator.examinationResultDate)) return false;
      if (this.isEmptyValue(this.creator.state)) return false;
      if (this.creator.state == LeasePaymentCompanyTypes.OK) {
        if (this.isEmptyValue(this.creator.rate)) return false;
      }
      */

      return true;
    },

    formTitle() {
      if (this.viewStartState == LeasePaymentStartStates.NotComplete) return 'リース入金登録';
      if (this.viewStartState == LeasePaymentStartStates.Edit) return 'リース関係情報修正';
      return 'リース開始登録';
    },

    // 入金プロセスの表示か？
    hasViewStartState() {
      return this.viewStartState != 0;
    },

    // 「リース開始」で表示させたか？
    isVViewStartStateNotStart() {
      return (this.viewStartState & LeasePaymentStartStates.NotStart) != 0;
    },

    isNG() {
      return this.creator.state == 1 << 3;
    },
  },
  data: () => ({
    //
    initializing: true,

    apo: null,
    demo: null,
    leasePayment: null,
    //
    expansions: null, // multipleではいので単体
    //
    leaseCompanyType: null,
    examinationResultDate: null,
    state: null,
    rate: null,
    residualBonds: null,
    underwritingBonds: null,
    complimentaryBonds: null,

    memo: null,

    startDate: null,

    completeScheduleDate: null,
    completeDate: null,

    tmpAmount: null,
    tmpTax: 0.1,

    amount: null,
    ngDate: null,

    meta: {
      ngMessage: null,
    },

    examinationResult: {
      results: [],
    },

    creator: {
      loading: false,

      leaseCompanyType: null,
      examinationResultDate: null,
      state: null,
      rate: null,
      residualBonds: null,
      underwritingBonds: null,
      complimentaryBonds: null,

      memo: null,

      startDate: null,

      completeScheduleDate: null,
      completeDate: null,
      amount: null,
      ngDate: null,

      meta: {
        ngMessage: null,
      },

      examinationResult: {
        results: [],
      },
    },
  }),
  methods: {
    init() {
      //
      //
      this.expansions = null;
      //
      this.tmpAmount = null;
      this.tmpTax = 0.1;

      this.leaseCompanyType = null;
      this.memo = null;
      //

      //
      this.initExaminationResult();
    },
    //
    onLoaded() {
      //
      this.initializing = true;
      //
      this.init();

      //
      this.get('leasePayment', { leasePaymentCode: this.leasePaymentCode })
        .then((success) => {
          //
          let data = success.data;

          this.leasePayment = data;
          this.demo = data.demo;
          if (data.demo != null) {
            this.apo = data.demo.apo;

            this.residualBonds = data.demo.residualBonds;
            this.underwritingBonds = data.demo.underwritingBonds;
            this.complimentaryBonds = data.demo.complimentaryBonds;
            this.creator.residualBonds = data.demo.residualBonds;
            this.creator.underwritingBonds = data.demo.underwritingBonds;
            this.creator.complimentaryBonds = data.demo.complimentaryBonds;
          }
          //

          this.leaseCompanyType = data.leaseCompanyType;
          this.examinationResultDate = data.examinationResultDate;
          this.state = data.state;
          this.rate = data.rate;
          this.memo = data.memo;
          this.meta = data.meta;

          this.startDate = data.startDate;

          this.completeScheduleDate = data.completeScheduleDate;
          this.completeDate = data.completeDate;
          this.amount = data.amount;
          this.ngDate = data.ngDate;
          // リース審査結果（画面側で表示するデータと登録データを引き合わせる
          if (data.examinationResult != null && Array.isArray(data.examinationResult.results)) {
            this.examinationResult.results.forEach((a) => {
              let result = data.examinationResult.results.find((b) => b.leaseCompanyType == a.leaseCompanyType);
              if (result == null) return;
              // creatorとインスタンスを共有してしまうのでクローンする
              let result2 = Object.assign({}, result);
              a.state = result2.state;
              // a.examinationDate = result2.examinationDate;
              a.examinationResultDate = result2.examinationResultDate;
              a.report = result2.report;
              a.memo = result2.memo;
            });
          }
          //

          this.creator.leaseCompanyType = data.leaseCompanyType;
          this.creator.examinationResultDate = data.examinationResultDate;
          this.creator.state = data.state;
          this.creator.rate = data.rate;
          this.creator.memo = data.memo;
          this.creator.meta = data.meta;

          this.creator.startDate = data.startDate;
          this.creator.completeScheduleDate = data.completeScheduleDate;
          this.creator.completeDate = data.completeDate;
          this.creator.amount = data.amount;
          this.creator.ngDate = data.ngDate;
          // リース審査結果（画面側で表示するデータと登録データを引き合わせる
          if (data.examinationResult != null && Array.isArray(data.examinationResult.results)) {
            this.creator.examinationResult.results.forEach((a) => {
              let result = data.examinationResult.results.find((b) => b.leaseCompanyType == a.leaseCompanyType);
              if (result == null) return;

              // creatorとインスタンスを共有してしまうのでクローンする
              let result2 = Object.assign({}, result);
              a.state = result2.state;
              a.report = result2.report;
              // a.examinationDate = result2.examinationDate;
              a.examinationResultDate = result2.examinationResultDate;
              a.memo = result2.memo;
            });
          }

          this.initializing = false;
        })
        .catch(() => {
          this.showError('データ取得に失敗しました');
          this.$emit('error');
        });
    },

    /**
     * フォーム決定イベント
     */
    async onCommit() {
      //
      if (!(await this.form.validate())) return;

      // allNG状態か？
      let allNG =
        this.creator.examinationResult.results.filter((a) => a.report).filter((a) => a.state == 1 << 3).length ==
        this.creator.examinationResult.results.length;
      if (allNG && confirm('全件NGで登録してもよろしいですか？')) {
        this.creator.state = 1 << 4;
      }
      //
      if (!confirm('登録してよろしいですか？')) return;
      //
      let request = this.creator;
      request.leasePaymentCode = this.leasePaymentCode;
      request.examinationResultDate = this.convertDate(this.creator.examinationResultDate);
      request.startDate = this.convertDate(this.creator.startDate);
      request.completeScheduleDate = this.convertDate(this.creator.completeScheduleDate);
      request.completeDate = this.convertDate(this.creator.completeDate);
      request.ngDate = this.convertDate(this.creator.ngDate);

      request.rate = this.rate;

      // nullable
      request.rate = this.convertNumber(request.rate);
      request.residualBonds = this.convertNumber(request.residualBonds);
      request.underwritingBonds = this.convertNumber(request.underwritingBonds);
      request.complimentaryBonds = this.convertNumber(request.complimentaryBonds);
      request.amount = this.convertNumber(request.amount);

      // FIXME 処理結果によって後続でメール送信有無を分けるように
      this.put('leasePayment', request)
        .then((success) => {
          //
          this.showSuccessSnackbar(success);
          //
          this.$emit('commit', this.leasePaymentCode);
        })
        .catch((error) => this.showErrorSnackbar(error));
    },

    onCancelClicked() {
      this.$emit('cancel');
    },

    initExaminationResult() {
      this.examinationResult.results = [
        LeasePaymentCompanyTypes.Orix,
        LeasePaymentCompanyTypes.CreditSaison,
        LeasePaymentCompanyTypes.Smbc,
        LeasePaymentCompanyTypes.Others,
      ].map((a) => ({ leaseCompanyType: a, state: null, examinationDate: null, examinationResultDate: null, report: false, memo: null }));

      // view側の一覧を使用するためcreatorも初期化
      this.creator.examinationResult.results = [
        LeasePaymentCompanyTypes.Orix,
        LeasePaymentCompanyTypes.CreditSaison,
        LeasePaymentCompanyTypes.Smbc,
        LeasePaymentCompanyTypes.Others,
      ].map((a) => ({
        leaseCompanyType: a,
        state: 1, // default:リース会社提出待ち
        examinationDate: null,
        examinationResultDate: null,
        report: false,
        memo: null,
      }));
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {
    // this.onLoaded();
  },
};
</script>