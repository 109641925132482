 <template>
  <div v-if="value != null && value.length > 0" class="">
    <v-icon :color="color" v-text="icon" left />
    <template v-for="(message, index) in value">
      <span :class="`${color}--text`" :key="index">{{ message }}</span>
    </template>
  </div>
</template>


<script>
export default {
  components: {},

  props: {
    value: {
      type: Array,
      default: () => null,
    },

    icon: {
      type: String,
      default: () => null,
    },

    color: {
      type: String,
      default: () => 'warning',
    },
  },
  methods: {},
};
</script>