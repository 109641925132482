<template>
  <v-tooltip color="cyan lighten-2" bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon color="cyan lighten-2" v-text="'mdi-flask-empty-outline'" small v-bind="attrs" v-on="on" />
    </template>
    <span>ダミー</span>
  </v-tooltip>
</template>

<script>
export default {
  components: {},
  props: {},
  computed: {},

  data: () => ({}),

  methods: {
    onLoaded() {},
  },
  watch: {},
  mounted() {
    this.onLoaded();
  },
};
</script>