<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <div class="text-overline">デモ情報</div>
              <span>
                <XChipDummy v-if="demo.facility.isDummy" class="mr-2" />
                {{ demo.facility.name }}
                <span v-if="demo.facility.pref != null" class="ml-2 text-caption">
                  ({{ demo.facility.pref.name }}{{ demo.facility.areaName
                  }}<a :href="`tel:${demo.facility.tel}`" v-text="demo.facility.tel" />)
                </span>
              </span>
            </v-list-item-title>

            <v-list-item-subtitle>
              <span>デモ日{{ demo.date | date }} </span>
              <span class="ml-2">デモ結果{{ demo.resultType | demoResultType }} </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <span>{{ demo.systemTypes.map((a) => $options.filters.demoSystemType(a.value)) | implode }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="3">顧客</v-col>
              <v-col cols="9">
                <span>
                  {{ demo.facility.name
                  }}<span v-if="demo.facility.pref != null">
                    ({{ demo.facility.pref.name }}{{ demo.facility.areaName }}
                    <a :href="`tel:${demo.facility.tel}`" v-text="demo.facility.tel" />)
                  </span>
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">システム</v-col>
              <v-col cols="9"
                ><span class="ml-4">{{ demo.systemTypes.map((a) => $options.filters.demoSystemType(a.value)) | implode }}</span></v-col
              >
            </v-row>

            <v-row>
              <v-col cols="3">報告区分</v-col>
              <v-col cols="9">
                <div>{{ demo.reportType | demoReportType }}</div>
                <div>デモ日: {{ demo.date | date }}</div>
                <div>デモ結果: {{ demo.resultType | demoResultType }}</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">受注区分</v-col>
              <v-col cols="9">{{ demo.contractType | demoContractType }}</v-col>
            </v-row>

            <v-row>
              <v-col cols="3">売上区分</v-col>
              <v-col cols="9">
                <span>{{ demo.saleType | demoSaleType }}</span>
                <span class="ml-2">({{ demo.term | monthToYear }})</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">デモ担当</v-col>
              <v-col cols="9">
                <span v-if="demo.demointer != null">{{ demo.demointer.name }}</span>
                <span v-if="demo.branch != null" class="ml-2">{{ demo.branch.name }}</span>
                <span v-if="demo.team != null" class="ml-2">{{ demo.team.name }}</span>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3">受注金額(税抜)</v-col>
              <v-col cols="9">{{ demo.sales | priceJP }}</v-col>
            </v-row>

            <v-row>
              <v-col cols="3">残債</v-col>
              <v-col cols="9">{{ demo.residualBonds | priceJP }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="3">他社買取</v-col>
              <v-col cols="9">{{ demo.underwritingBonds | priceJP }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="3">サービス品</v-col>
              <v-col cols="9">{{ demo.complimentaryBonds | priceJP }}</v-col>
            </v-row>

            <v-row>
              <v-col cols="3">売上見込み</v-col>
              <v-col cols="9">{{ demo.prospectAmount | priceJP }}</v-col>
            </v-row>

            <v-row>
              <v-col cols="3">初期費用(税抜)</v-col>
              <v-col cols="9">{{ demo.initialCost | priceJP }}</v-col>
            </v-row>

            <v-row>
              <v-col cols="3">オプション</v-col>
              <v-col cols="9">
                <span class="" v-if="demo.meta.cloud != null"
                  >クラウド:<strong v-text="$options.filters.metaCloud(demo.meta.cloud.id)"
                /></span>
                <span class="ml-2" v-if="demo.meta.shuukyaku_support != null"
                  >集客サポート:<strong v-text="$options.filters.metaShuukyaku_support(demo.meta.shuukyaku_support.id)" />
                </span>
                <span class="ml-2" v-if="demo.meta.kaikei_support != null"
                  >会計サポート:<strong v-text="$options.filters.metaKaikei_support(demo.meta.kaikei_support.id)"
                /></span>
                <span class="ml-2" v-if="demo.meta.katyao != null"
                  >かっちゃお:<strong v-text="$options.filters.metaKatyao(demo.meta.katyao.id)"
                /></span>
                <span class="ml-2" v-if="demo.meta.hp != null">HP:<strong v-text="$options.filters.metaHp(demo.meta.hp.id)" /></span>
                <span class="ml-2" v-if="demo.meta.yokoku_hp != null"
                  >予告HP:<strong v-text="$options.filters.metaYokoku_hp(demo.meta.yokoku_hp.id)"
                /></span>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3">事前審査提出日</v-col>
              <v-col cols="9">{{ demo.submitPreExaminationDate | date }}</v-col>
            </v-row>

            <v-row>
              <v-col cols="3">備考</v-col>
              <v-col cols="9" v-html="$options.filters.md(demo.memo)"></v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="text-caption">{{ demo.code }}</v-card-actions>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {
    demo: {
      type: Object,
      required: true,
    },
  },
  computed: {},
  data: () => ({}),
  methods: {},
  watch: {},
  mounted() {
    // this.onLoaded();
  },
};
</script>