import Vue from 'vue';
// import marked from 'marked';
const marked = require('marked');

import { isDate } from '@/util/valid';

import { LeasePaymentCompanyTypes, AsunaroPlusTypes, YadoPlusTypes } from '@/types';

export function nl2br(value) {
  if (!value) {
    return '';
  }

  return value.replace(/\n/g, '<br/>');
}

export function implode(value, separator = ',') {
  if (!Array.isArray(value)) {
    return '';
  }

  return value.join(separator);
}

export function trim(value, length = 80) {
  return lessStr(value, length, '');
}

export function lessStr(value, length = 80, str = '...') {
  if (!value) {
    return '';
  }

  if (value.length > length) {
    return value.slice(0, length) + str;
  }
  return value;
}

// heatmap
const sigmoid = function (x, offset, gain) {
  return (Math.tanh(((x + offset) * gain) / 2) + 1) / 2;
};

const toHex = function (num) {
  let hex = Math.floor(num).toString(16);

  if (hex.length == 1) {
    hex = '0' + hex;
  }
  return hex;
};

export function thermo(val) {
  val = val * 2 - 1;
  let red = sigmoid(val, -0.2, 10);
  let blue = 1 - sigmoid(val, 0.2, 10);
  let green = sigmoid(val, 0.6, 10) + (1 - sigmoid(val, -0.6, 10));
  green = green - 1.0;

  return '#' + toHex(red * 255) + toHex(green * 255 * 0.9) + toHex(blue * 255);
}

/**
 *
 * @param value
 */
export function priceJP(value, prefix = '\xA5') {
  if (value == undefined || value == null) {
    return '';
  }
  //
  value = Math.floor(value);

  let val = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return prefix + val;
}

export function percent(value, fix = 2) {
  if (value == undefined) return '';
  if (value == null) return '';
  if (isNaN(value)) return '';
  if (!isFinite(value)) return '';

  let per = Math.floor(value * 100 * 10 ** fix) / 10 ** fix; // ** べき乗
  //
  per = per.toFixed(fix);
  return `${per}`;
}

export function date(date, format = 'YYYY/MM/DD') {
  //
  if (!isDate(date)) return '-';

  try {
    return Vue.moment(date).format(format); // eslint-disable-line
  } catch (ex) {
    console.error('filter:date ex', ex);
    return '-';
  }
}

export function time(date, format = 'HH:mm') {
  //
  if (!isDate(date)) return '-';
  try {
    return Vue.moment(date).format(format); // eslint-disable-line
  } catch (ex) {
    console.error('filter:time ex', ex);
    return '-';
  }
}

export function dateTime(date, format = 'YYYY/MM/DD HH:mm') {
  //
  if (!isDate(date)) return '-';
  try {
    return Vue.moment(date).format(format); // eslint-disable-line
  } catch (ex) {
    console.error('filter:dateTime ex', ex);
    return '-';
  }
}

export function dateMonth(date, format = 'YYYY/MM') {
  //
  if (!isDate(date)) return '-';
  try {
    return Vue.moment(date).format(format); // eslint-disable-line
  } catch (ex) {
    console.error('filter:dateTime ex', ex);
    return '-';
  }
}

export function md(value) {
  if (!value) return '';
  //
  value = nl2br(value);

  return marked.marked(value);
}

export function emailType(val) {
  switch (val) {
    case 1:
      return 'To';
    case 1 << 1:
      return 'CC';
    case 1 << 2:
      return 'BCC';
    case 1 << 3:
  }
  return '';
}

export function apoType(val) {
  switch (val) {
    case 1:
      return '新規';
    case 1 << 1:
      return '再デモ';
    case 1 << 2:
      return 'リプレイス';
    case 1 << 3:
      return '元客';
    case 1 << 4:
      return 'バージョンアップ';
    case 1 << 5:
      return '即デモ';

    case 1 << 9:
      return '入力ミス(非表示処理)';
  }
  return '';
}

export function apoState(val) {
  switch (val) {
    case 1:
      return '新規';
    case 1 << 1:
      return 'デモ日程確認済み';
    case 1 << 2:
      return 'デモ日程変更';
    case 1 << 3:
      return 'デモ済み';
    case 1 << 5:
      return 'キャンセル済み';
    case 1 << 9:
      return '再デモ';
  }
  return '';
}
export function apoStateColor(val) {
  switch (val) {
    case 1:
      return 'lime lighten-4';
    case 1 << 1:
      return 'teal accent-1';
    case 1 << 2:
      return 'orange lighten-4';
    case 1 << 5:
      return 'red lighten-4';
    case 1 << 9:
      return 'pink lighten-3';
  }
  return '';
}

export function demo_naiyou(val) {
  switch (val) {
    case 1:
      return '宿あすなろ';
    case 1 << 1:
      return 'すけさんwebサポート';
    case 1 << 2:
      return 'すけさんフルサポート';
    case 1 << 3:
      return 'かくさん';
    case 1 << 4:
      return 'かっちゃお！';
    case 1 << 6:
      return '商売管理';
    case 1 << 7:
      return 'その他(詳細は備考)';
  }
  return '';
}

export function demoReportType(val) {
  switch (val) {
    case 1:
      return '初回デモ結果';
    case 1 << 1:
      return '即デモ結果';
    case 1 << 2:
      return '再デモ結果';
    case 1 << 3:
      return '返事結果';
    // リプレイスとして処理しない（詳しくはAPI.Typesの定義を参照！)
    case 1 << 4:
      return 'リプデモ';

    case 1 << 9:
      return '入力ミス(非表示処理)';
  }
  return '';
}

export function demoResultType(val) {
  switch (val) {
    case 1:
      return '未デモ';
    case 1 << 1:
      return '受注';
    case 1 << 2:
      return 'NG';
    case 1 << 3:
      return '返事待ち';
    case 1 << 4:
      return 'キャンセル';

    case 1 << 5:
      return 'その他';

    case 1 << 9:
      return '再デモ';
  }
  return '';
}

export function demoContractType(val) {
  switch (val) {
    case 1:
      return '新規';
    case 1 << 1:
      return 'リプレイス';
    case 1 << 2:
      return '元客';
    case 1 << 3:
      return 'バージョンアップ';
  }
  return '';
}

export function demoSaleType(val) {
  switch (val) {
    case 1:
      return 'リース';
    case 1 << 1:
      return 'レンタル';
    case 1 << 2:
      return '現金';
    case 1 << 3:
      return '手数料';
    case 1 << 4:
      return '仕入販売';
  }
  return '';
}

export function demoSaleTypeColor(val) {
  switch (val) {
    case 1:
      return 'lime lighten-4';
    case 1 << 1:
      return 'teal accent-1';
    case 1 << 2:
      return 'red lighten-4';
    case 1 << 3:
      return 'purple lighten-4';
    case 1 << 4:
      return 'orange lighten-4';
  }
  return '';
}

export function demoSystemType(val) {
  switch (val) {
    case 1:
      return;
    default:
      return '';
    case 1 << 1:
      return '総合管理';
    case 1 << 2:
      return '売上管理';
    case 1 << 3:
      return '予約管理';
    case 1 << 4:
      return 'かくさん';
    case 1 << 5:
      return '商売管理';
    case 1 << 6:
      return '顧客管理';
    case 1 << 7:
      return 'プラン予約';
    case 1 << 8:
      return 'その他';
  }
}

export function leasePaymentCompanyType(val) {
  switch (val) {
    case LeasePaymentCompanyTypes.Orix:
      return 'オリックス';
    case LeasePaymentCompanyTypes.CreditSaison:
      return 'クレディセゾン';
    case LeasePaymentCompanyTypes.Smbc:
      return '三井住友ファイナンス';
    case LeasePaymentCompanyTypes.Others:
      return 'その他';
    default:
      return '';
  }
}

export function leasePaymentCompanyTypeShort(val) {
  switch (val) {
    case LeasePaymentCompanyTypes.Orix:
      return 'オリ';
    case LeasePaymentCompanyTypes.CreditSaison:
      return 'クレディ';
    case LeasePaymentCompanyTypes.Smbc:
      return '三井住友';
    case LeasePaymentCompanyTypes.Others:
      return 'その他';
    default:
      return '';
  }
}

export function leasePaymentState(val) {
  switch (val) {
    case 1:
      return '提出待';
    case 1 << 2:
      return 'OK';
    case 1 << 3:
      return 'NG';
    case 1 << 4:
      return '全NG';
    default:
      return '';
  }
}

export function metaCloud(val) {
  switch (val) {
    case '1':
      return 'なし';
    case '2':
      return 'あり(有償)';
    case '3':
      return 'あり(サービス)';
    default:
      return '';
  }
}

export function metaShuukyaku_support(val) {
  switch (val) {
    case '1':
      return 'なし';
    case '2':
      return 'すけさん';
    case '3':
      return 'やどーだ!';
    default:
      return '';
  }
}

export function metaKaikei_support(val) {
  switch (val) {
    case '1':
      return 'なし';
    case '2':
      return 'あり';
    default:
      return '';
  }
}

export function metaKatyao(val) {
  switch (val) {
    case '1':
      return 'なし';
    case '2':
      return 'あり';
    default:
      return '';
  }
}

export function metaHp(val) {
  switch (val) {
    case '1':
      return 'なし';
    case '2':
      return 'HP';
    case '3':
      return 'スマホ';
    case '4':
      return 'HP+スマホ';
    default:
      return '';
  }
}

export function metaYokoku_hp(val) {
  switch (val) {
    case '1':
      return 'なし';
    case '2':
      return 'あり';
    default:
      return '';
  }
}

export function asunaroPlusType(val) {
  switch (val) {
    case AsunaroPlusTypes.Order:
      return 'オーダー';
    case AsunaroPlusTypes.Kitchen:
      return 'キッチン';
    default:
      return '';
  }
}

export function yadoPlusType(val) {
  switch (val) {
    case YadoPlusTypes.Ryosenkikaku:
      return '良選企画';
    default:
      return '';
  }
}

export function leasePaymentStartState(val) {
  switch (val) {
    case 1:
      return '未回収';
    case 1 << 1:
      return '回収OK';
    case 1 << 2:
      return '業務回収OK';
    case 1 << 3:
      return '開始OK';
    case 1 << 4:
      return 'その開始NG他';
    case 1 << 5:
      return '入金OK';
    case 1 << 6:
      return '入金NG';
    case 1 << 7:
      return 'レンタル';
    default:
      return '';
  }
}

export function coverageCollectTypes(val) {
  switch (val) {
    case 1:
      return '未回収';
    case 1 << 1:
      return '回収OK(提出待ち)';
    case 1 << 2:
      return '提出OK';
    case 1 << 9:
      return 'レンタル・現金';
    default:
      return '';
  }
}

export function demoResultTypeColor(val) {
  switch (val) {
    case 1:
      return 'lime lighten-4';
    case 1 << 1:
      return 'teal accent-1';
    case 1 << 2:
      return 'red lighten-4';
    case 1 << 3:
      return 'purple lighten-4';
    case 1 << 4:
      return 'orange lighten-4';

    case 1 << 5:
      return 'blue-grey lighten-4';

    case 1 << 9:
      return 'pink lighten-3';
  }
  return '';
}

export function domain(val) {
  switch (val) {
    case 1:
      return '既存ドメイン使用';
    case 1 << 1:
      return 'ドメイン移管';
    case 1 << 2:
      return '取得代行';
    default:
      return '';
  }
}

export function saleType(val) {
  switch (val) {
    case 1:
      return 'WEB';
    case 1 << 1:
      return 'レンタル';
    case 1 << 2:
      return '現金';
    case 1 << 3:
      return 'すけさん';
    case 1 << 5:
      return 'その他';
    default:
      return '';
  }
}

export function saleTypeColor(val) {
  switch (val) {
    case 1:
      return 'lime ';
    case 1 << 1:
      return 'teal ';
    case 1 << 2:
      return 'orange';
    case 1 << 3:
      return 'red';
    case 1 << 5:
      return 'pink ';
    default:
      return '';
  }
}

export function saleSummaryType(val) {
  switch (val) {
    case 1:
      return 'なし';
    case 1 << 1:
      return '日';
    case 1 << 2:
      return '月';
    case 1 << 5:
      return '年';
    default:
      return '';
  }
}

export function saleSummaryTypeColor(val) {
  switch (val) {
    case 1:
      return 'lime lighten-4';
    case 1 << 1:
      return 'teal accent-1';
    case 1 << 2:
      return 'red lighten-4';
    case 1 << 5:
      return 'orange lighten-4';
    default:
      return '';
  }
}

export function merginType(val) {
  switch (val) {
    case 1:
      return 'すけさん';
    case 1 << 1:
      return 'レンタル';
    case 1 << 2:
      return 'やどーだ';
    case 1 << 3:
      return 'スマホ';
    case 1 << 4:
      return '解約違約金';
    case 1 << 5:
      return '営業';
    default:
      return '';
  }
}

export function monthToYear(month) {
  if (0 || isNaN(month)) return '';
  return month / 12 + '年';
}

// 売上見込み金額計算
export function calcLeaseAmount(sales, saleType, rate, term) {
  if (sales == null) return null;
  if (rate == null || rate == 0) return null;

  //
  // 現金の場合
  if (saleType == 1 << 2) {
    // 入力値が「売り上げ見込み」となる
    return sales;
  } else if (saleType == 1 << 1) {
    // レンタル
    // 入力値が月々の支払い金額となるため「営業的な」売上見込みは立たないらしい
    if (rate == null || term == null) return null;
    // return sales * term;
    return null;
  } else {
    let res = sales / (rate / 100);
    // 10の位で切り捨て
    return Math.floor(res / 100) * 100;
  }

  // if (sales == null || sales == 0) return null;
  // if (rate == null || rate == 0) {
  //   if (term == null || term == 0) return null;
  //   // 料率設定がなければ売り上げ見込みは 売上*期間(月)
  //   return sales * term;
  // }

  // let res = sales / (rate / 100);
  // // 10の位で切り捨て
  // return Math.floor(res / 100) * 100;
}

// 期首日を取得します
export function getTermFirstDate(date) {
  if (date == null) return null;
  let m = Vue.moment(date);
  let year = m.year();
  let month = m.month();
  // 期首の年
  // 1～5月は前の年をとる
  if (month >= 0 && month <= 4) year -= 1;

  return new Date(year, 6 - 1, 1);
}

export function libertyTerm(date) {
  if (date == null) return null;

  let term = date.getFullYear() - 1984;
  // 1-5月であればひとつ前の期
  let month = date.getMonth() + 1;
  if (month >= 1 && month <= 5) term -= 1;
  //
  return term;
}

export function libertyTermToFirstDate(term) {
  if (date == null) return null;
  let year = term + 1984;
  //
  return new Date(year, 6 - 1, 1);
}

export function libertyTermToEndDate(term) {
  // 期の最初の日を取得
  let date = libertyTermToFirstDate(term);
  let m = Vue.moment(date).add(1, 'y').add(-1, 'd');
  //
  return m.toDate();
}
