<template>
  <!--FIXME localeハードコーディング中-->
  <div class="d-flex justify-space-between">
    <v-date-picker v-if="isMonthMode" v-model="monthVal" type="month" locale="jp-ja" @change="onCommit('month')" />
    <v-date-picker
      v-else
      v-model="dateVal"
      locale="jp-ja"
      :day-format="(dateVal) => new Date(dateVal).getDate()"
      @change="onCommit('date')"
    />
    <template v-if="!hideTime && !isMonthMode">
      <v-spacer />
      <v-time-picker v-model="timeVal" @change="onCommit('time')"></v-time-picker>
    </template>
  </div>
</template>
<script>
export default {
  components: {},

  props: {
    mode: {
      type: String,
      default: () => null,
    },

    month: {
      type: String,
      default: () => null,
    },

    date: {
      type: String,
      default: () => null,
    },
    time: {
      type: String,
      default: () => null,
    },

    hideTime: {
      type: Boolean,
      default: () => false,
    },
  },

  computed: {
    isMonthMode() {
      return this.mode == 'month';
    },
  },

  data: () => ({
    dialog: false,
    monthVal: null,
    dateVal: null,
    timeVal: null,
  }),
  methods: {
    onLoaded() {
      this.monthVal = this.month;
      this.dateVal = this.date;
      this.timeVal = this.time;
    },

    onCommit(mode) {
      this.$emit('update:month', this.monthVal);
      this.$emit('update:date', this.dateVal);
      this.$emit('update:time', this.timeVal);

      //
      this.$emit('commit', mode);
    },
  },

  // mountedでは機能しない
  created() {
    this.onLoaded();
  },
};
</script>
