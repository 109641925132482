// import webconfig from '@/web-configs';

import { jsonToForm } from '@/util/jsonToForm';

import axios from 'axios';

import store from '@/store';

import { Icons } from '@/types';

import { isDate, isEmpty, isEmptyValue } from '@/util/valid';

import { AnalyseSaleTypes } from '@/views/managements/analyses/Viewer';

export default {
  computed: {
    isLogined() {
      return store.getters.user.isLogined;
    },

    /** アプリケーションのバージョン */
    version() {
      // return webconfig.web.version;
      return require('../../package.json').version;
    },

    /** 通信中か？ */
    isRequesting() {
      return store.getters.isRequesting;
    },

    /** データ */
    dataContext() {
      return store.getters.dataContext;
    },

    Icons() {
      return Icons;
    },

    prefAreas() {
      return [
        { code: '1', name: '北海道・東北', prefIds: [1, 2, 3, 4, 5, 6, 7] },
        { code: '2', name: '関東・甲信越', prefIds: [8, 9, 10, 11, 12, 13, 14, 19, 20] },
        { code: '3', name: '北陸', prefIds: [15, 16, 17, 18] },
        { code: '4', name: '東海', prefIds: [21, 22, 23, 24] },
        { code: '5', name: '近畿', prefIds: [25, 26, 27, 28, 29, 30] },
        { code: '6', name: '中国', prefIds: [31, 32, 33, 34, 35] },
        { code: '7', name: '四国', prefIds: [36, 37, 38, 39] },
        { code: '8', name: '九州', prefIds: [40, 41, 42, 43, 44, 45, 46, 47] },
      ];
    },
  },

  data: () => ({}),
  methods: {
    isEmpty(str) {
      return isEmpty(str);
    },
    isEmptyValue(val) {
      return isEmptyValue(val);
    },

    isDate(val) {
      return isDate(val);
    },
    isString(obj) {
      return typeof obj === 'string' || obj instanceof String;
    },

    hasFlag(val, except) {
      if (val == null || isNaN(val)) return false;

      return (val & except) != 0;
    },

    getTerms(years) {
      let date = this.$options.filters.getTermFirstDate(new Date());
      // 現在を含めた前３年
      return [...Array(years).keys()].map((a) => {
        let moment = this.$moment(date);
        let d = moment.add(-a, 'years').toDate();
        return {
          text: `${this.$options.filters.libertyTerm(d)}期 `,
          value: this.$options.filters.libertyTerm(d),
        };
      });
    },

    getKokyakus(creator) {
      // return this.dataContext.kokyakus;
      let datas = this.dataContext.kokyakus;
      //
      if (creator.pref != null) {
        datas = this.dataContext.kokyakus.filter((a) => a.pref == creator.pref);
      }
      //
      return datas.map((a) => {
        return { text: a.name, value: a.id };
      });
    },

    setKokyakuData(creator, kokyaku_code) {
      if (kokyaku_code == null) return;

      let kokyaku = this.dataContext.kokyakus.find((a) => a.id == kokyaku_code);
      if (kokyaku == null) return;
      creator.kokyaku_code = kokyaku.id;
      creator.kokyaku_name = kokyaku.name;
    },

    /**
     *
     */
    onKokyakuNameChanged(creator) {
      //
      let val = creator.kokyaku_name;

      if (val == null) return;
      // getKokyakusでmapしているアトリビュート名と同じであることに注意
      let kokyaku = this.dataContext.kokyakus.find((a) => a.name == val || a.id == val.value);

      if (kokyaku == null) {
        creator.kokyaku_code = null;
        creator.kokyaku_name = val;
      } else {
        creator.kokyaku_code = kokyaku.id;
        creator.kokyaku_name = kokyaku.name;
      }
    },

    sendMailApo(apoCode) {
      //
      if (!confirm('メール配信します。よろしいですか？')) return;

      this.post('mail/apo', { apoCode: apoCode })
        .then(() => this.showSuccess('メール配信しました'))
        .catch((error) => this.showErrorSnackbar(error));
    },

    sendMailDemo(demoCode) {
      //
      if (!confirm('メール配信します。よろしいですか？')) return;
      //
      this.post('mail/demo', { demoCode: demoCode })
        .then(() => this.showSuccess('メール配信しました'))
        .catch((error) => this.showErrorSnackbar(error));
    },
    sendMailLeasePayment(leasePaymentCode) {
      //
      if (!confirm('メール配信します。よろしいですか？')) return;
      //
      this.post('mail/leasePayment', { leasePaymentCode: leasePaymentCode })
        .then(() => this.showSuccess('メール配信しました'))
        .catch((error) => this.showErrorSnackbar(error));
    },
    sendMailHPCoverage(hpCoverageCode) {
      //
      if (!confirm('メール配信します。よろしいですか？')) return;
      //
      this.post('mail/hpCoverage', { hpCoverageCode: hpCoverageCode })
        .then(() => this.showSuccess('メール配信しました'))
        .catch((error) => this.showErrorSnackbar(error));
    },

    sendMailHPCreate(hpCreateCode) {
      //
      if (!confirm('メール配信します。よろしいですか？')) return;
      //
      this.post('mail/hpCreate', { hpCreateCode: hpCreateCode })
        .then(() => this.showSuccess('メール配信しました'))
        .catch((error) => this.showErrorSnackbar(error));
    },

    getPrefArea(prefID) {
      return this.prefAreas.find((a) => a.prefIds.includes(prefID));
    },

    convertDate(val) {
      let date = val;
      if (date != null) {
        date = this.$moment(date).format();
      }
      return date;
    },

    convertNumber(val) {
      try {
        if (val == '') throw new Error('is empty');
        if (val == null) throw new Error('is null');

        let result = Number(val);
        if (isNaN(result)) throw new Error('Nan');
        return result;
      } catch {
        return null;
      }

      // if(!(new RegExp(/^[0-9]+(\.[0-9]+)?$/).test(val)))return null;
    },

    analyseData(datas, date, type, branchCode, teamCode, weeks, sectionType) {
      // 現在のmoment
      const nowM = this.$moment(new Date());
      // 一応クローンする
      let datasX = Object.assign([], datas);

      datasX = datasX.filter((a) => a.branch != null).filter((a) => a.team != null);

      // 日付は年・月で絞る
      if (date != null) {
        datasX = datasX.filter((a) => a.year == date.getFullYear()).filter((a) => a.month == date.getMonth() + 1);
      }

      // nullの場合、補正値の値はのぞく
      if (type != null) {
        datasX = datasX.filter((a) => a.type == type);
      } else {
        // 補正および小計用データ以外
        datasX = datasX.filter((a) => a.type != AnalyseSaleTypes.CorrectedTotal).filter((a) => a.type != AnalyseSaleTypes.ResultTotal);
      }

      if (branchCode != null) datasX = datasX.filter((a) => a.branch.code == branchCode);
      if (teamCode != null) datasX = datasX.filter((a) => a.team.code == teamCode);

      // 部門種別指定がある場合
      if (sectionType != null) {
        datasX = datasX.filter((a) => a.branch.sectionType == sectionType);
      }

      // if (teamCode == '2') {
      //   console.log(`teamCode:${teamCode},date:${date},type:${type}`);
      //   console.log({ datasX });
      // }
      //
      const prospectAmount = datasX.reduce((sum, a) => (sum += a.prospectAmount), 0);
      const amount = datasX.reduce((sum, a) => (sum += a.amount), 0);
      const quotas = datasX.reduce((sum, a) => (sum += a.quotas), 0);

      // 当月までの累計予算
      const quotasBeforeNow = datasX
        .filter((a) => {
          const dataM = this.$moment(a.date);
          return dataM.isSameOrBefore(nowM, 'month');
        })
        .reduce((sum, a) => (sum += a.quotas), 0);

      // レンタル・Web・その他・宿プラス(業販)の場合のみ売上見込みを売上実績で「見た目上表示」（実際データは見込みではない)させる
      const prospectAmount2Targets = [AnalyseSaleTypes.Rental, AnalyseSaleTypes.Web, AnalyseSaleTypes.Other, AnalyseSaleTypes.YadoPlus];
      const prospectAmount2 = datasX.reduce((sum, a) => (sum += prospectAmount2Targets.includes(a.type) ? a.amount : a.prospectAmount), 0);

      const isManual = datasX.filter((a) => a.isManual).length > 0;
      const quotaMessages = datasX.flatMap((a) => a.quotaMessages);

      const demos = datasX.flatMap((a) => a.demos);

      let weekInMonths = [1, 2, 3, 4, 5].map((weekInMonth) => {
        const datasW = datasX.flatMap((a) => a.weekInMonths).filter((a) => a.weekInMonth == weekInMonth);
        const prospectAmountW = datasW.reduce((sum, a) => (sum += a.prospectAmount), 0);
        const amountW = datasW.reduce((sum, a) => (sum += a.amount), 0);
        const quotasW = datasW.reduce((sum, a) => (sum += a.quotas), 0);
        const isManualW = datasW.filter((a) => a.isManual).length > 0;
        const quotaMessagesW = datasW.flatMap((a) => a.quotaMessages);

        return {
          weekInMonth: weekInMonth,
          prospectAmount: prospectAmountW,
          amount: amountW,
          quotas: quotasW,
          prospectPercent: prospectAmountW / quotasW,
          percent: amountW / quotasW,
          isManual: isManualW,
          quotaMessages: quotaMessagesW,

          quotasBeforeNow: quotasBeforeNow,
        };
      });
      if (Array.isArray(weeks)) weekInMonths = weekInMonths.filter((a) => weeks.includes(a.weekInMonth));

      return {
        prospectAmount: prospectAmount,
        prospectAmount2: prospectAmount2,
        amount: amount,
        quotas: quotas,
        demos: demos,
        weekInMonths: weekInMonths,
        prospectPercent: prospectAmount / quotas,
        prospectPercent2: prospectAmount2 / quotas,
        percent: amount / quotas,
        isManual: isManual,
        quotaMessages: quotaMessages,

        quotasBeforeNow: quotasBeforeNow,
      };
    },

    // 32bitを配列に変換
    convertBit2Ar(val) {
      let datas = [];
      if (val == null || val <= 0) return datas;
      //
      for (let i = 0; i < 32; i++) {
        let bit = 1 << i;
        if ((bit & val) != 0) datas.push(bit);
      }
      return datas;
    },

    // enumの配列を32bitの数値に変換
    convertAr2Bit(bits) {
      if (!Array.isArray(bits)) return 0;
      return bits.reduce((sum, a) => (sum += a), 0);
    },

    /* ----- http -------*/

    get(url, request, option) {
      // return new Promise(() => { });

      //
      if (!option)
        option = {
          loading: false,
          success: false,
        };
      // 初期化
      option.loading = true;
      option.success = false;

      //
      return (
        axios({
          method: 'get',
          url: url,
          params: request,
          headers: {
            Authorization: 'Bearer ' + store.getters.token,
            usercode: store.getters.userCode,
            license: store.getters.api.license,
          },
        })
          .then((success) => {
            // リクエスト完了、処理失敗
            option.loading = false;
            option.success = true;

            return success;
          })
          // intercepterで処理させているため、catchするがrejectしない
          .catch((error) => {
            // リクエスト完了、処理失敗
            option.loading = false;
            option.success = false;

            return Promise.reject(error);
          })
      );
    },

    post(url, request, option) {
      if (!option)
        option = {
          loading: false,
          success: false,
        };
      // 初期化
      option.loading = true;
      option.success = false;

      //
      return (
        axios({
          method: 'post',
          url: url,
          data: request,
          headers: {
            Authorization: 'Bearer ' + store.getters.token,
            usercode: store.getters.userCode,
            license: store.getters.api.license,
          },
        })
          .then((success) => {
            // リクエスト完了、処理失敗
            option.loading = false;
            option.success = true;

            return success;
          })
          // intercepterで処理させているため、catchするがrejectしない
          .catch((error) => {
            // リクエスト完了、処理失敗
            option.loading = false;
            option.success = false;

            return Promise.reject(error);
          })
      );
    },

    put(url, request, option) {
      //
      if (!option)
        option = {
          loading: false,
          success: false,
        };
      // 初期化
      option.loading = true;
      option.success = false;

      //
      return (
        axios({
          method: 'put',
          url: url,
          data: request,
          headers: {
            Authorization: 'Bearer ' + store.getters.token,
            usercode: store.getters.userCode,
            license: store.getters.api.license,
          },
        })
          .then((success) => {
            // リクエスト完了、処理失敗
            option.loading = false;
            option.success = true;

            return success;
          })
          // intercepterで処理させているため、catchするがrejectしない
          .catch((error) => {
            // リクエスト完了、処理失敗
            option.loading = false;
            option.success = false;

            return Promise.reject(error);
          })
      );
    },

    delete(url, request, option) {
      //
      if (!option)
        option = {
          loading: false,
          success: false,
        };
      // 初期化
      option.loading = true;
      option.success = false;

      //
      return (
        axios({
          method: 'delete',
          url: url,
          data: request,
          headers: {
            Authorization: 'Bearer ' + store.getters.token,
            usercode: store.getters.userCode,
            license: store.getters.api.license,
          },
        })
          .then((success) => {
            // リクエスト完了、処理失敗
            option.loading = false;
            option.success = true;

            return success;
          })
          // intercepterで処理させているため、catchするがrejectしない
          .catch((error) => {
            // リクエスト完了、処理失敗
            option.loading = false;
            option.success = false;

            return Promise.reject(error);
          })
      );
    },

    /**
     *
     */
    postUpload(url, request, files, option) {
      //
      if (!option)
        option = {
          loading: false,
          success: false,
        };
      // 初期化
      option.loading = true;
      option.success = false;

      // 送信用オブジェクトに入れ替える
      let params = this.createFormData('files', files); // FIXME:ハードコーディング中
      //

      params = jsonToForm(request, params);
      //
      const config = {
        headers: {
          Authorization: 'Bearer ' + store.getters.token,
          usercode: store.getters.userCode,
          license: store.getters.api.license,

          'content-type': 'multipart/form-data',
        },
      };
      //
      return (
        axios
          .post(url, params, config)
          .then((success) => {
            // リクエスト完了、処理失敗
            option.loading = false;
            option.success = true;

            return success;
          })
          // intercepterで処理させているため、catchするがrejectしない
          .catch((error) => {
            // リクエスト完了、処理失敗
            option.loading = false;
            option.success = false;

            return Promise.reject(error);
          })
      );
    },
    /**
     *
     */
    putUpload(url, request, files, option) {
      //
      if (!option)
        option = {
          loading: false,
          success: false,
        };
      // 初期化
      option.loading = true;
      option.success = false;

      // 送信用オブジェクトに入れ替える
      let params = this.createFormData('files', files); // FIXME:ハードコーディング中
      //

      params = jsonToForm(request, params);
      //
      const config = {
        headers: {
          Authorization: 'Bearer ' + store.getters.token,
          usercode: store.getters.userCode,
          license: store.getters.api.license,

          'content-type': 'multipart/form-data',
        },
      };
      //
      return (
        axios
          .put(url, params, config)
          .then((success) => {
            // リクエスト完了、処理失敗
            option.loading = false;
            option.success = true;

            return success;
          })
          // intercepterで処理させているため、catchするがrejectしない
          .catch((error) => {
            // リクエスト完了、処理失敗
            option.loading = false;
            option.success = false;

            return Promise.reject(error);
          })
      );
    },

    //#region  変換

    createFormData(name, files) {
      if (!Array.isArray(files)) {
        files = [];
      }

      // 送信用FormDataオブジェクト
      let params = new FormData();
      params.append('send', true);

      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        params.append(name, file);
      }
      //
      return params;
    },

    /**
     *
     * @param {*} success
     */
    showSuccessSnackbar(success) {
      if (success != null && success.data != null) {
        //
        this.showSnackbar({
          message: '登録完了しました',
          show: true,
          color: 'success',
        });
        //
        this.setFormChanged(false);
      }
    },

    /**
     *
     * @param {*} error
     */
    showErrorSnackbar(error) {
      if (error != null && error.response != null) {
        let response = error.response;
        let data = response.data;

        //
        this.showSnackbar({
          message: `${response.status} [${data.json.title}]  ${data.json.message}`,
          show: true,
          color: 'error',
        });
      }
    },

    showToast(option) {
      this.$toasted.show(option.message, option);
    },

    showSnackbar(option) {
      option.show = true;
      store.commit('setSnackbar', option);
    },

    showSuccess(message) {
      //
      this.showSnackbar({
        message: message,
        show: true,
        color: 'success',
      });
    },

    showError(message) {
      //
      this.showSnackbar({
        message: message,
        show: true,
        color: 'error',
      });
    },

    setFormChanged(val) {
      store.commit('setFormChanged', val);
    },
  },

  beforeRouteLeave(to, from, next) {
    next();
  },
};
