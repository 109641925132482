export function isString(obj) {
  //
  if (obj == null) return false;
  return typeof obj === 'string' || obj instanceof String;
}

export function isDate(obj) {
  //
  if (obj == null) return false;

  return !isNaN(new Date(obj).getTime());
}

export function isEmpty(val) {
  if (val == null) return true;
  if (val == '') return true;
  return false;
}

export function isEmptyValue(val) {
  if (isEmpty(val)) return true;
  if (val == 0) return true;
  return false;
}

export function arrayEqual(a, b) {
  if (!Array.isArray(a)) return false;
  if (!Array.isArray(b)) return false;
  if (a.length != b.length) return false;
  for (var i = 0, n = a.length; i < n; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}
