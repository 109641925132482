<template>
  <v-dialog scrollable v-model="dialog" persistent :width="width">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs">
        <template v-if="isMultiple">
          <a text class="text--primary" dark v-bind="attrs" v-on="on">
            <span v-if="inputedValue.length >= 1">
              {{ labelFilter(inputedValue[0]) }}
            </span>
            <span v-if="inputedValue.length >= 2">
              -
              {{ labelFilter(inputedValue[1]) }}
            </span>
          </a>
        </template>
        <template v-else>
          <a text class="text--primary" dark v-bind="attrs" v-on="on">{{ labelFilter(inputedValue) }} </a>
        </template>
      </slot>
    </template>
    <v-card>
      <v-card-title class="text-h5">
        <slot name="title">
          <span> {{ label }}</span>
          <span v-if="month"> {{ newDate | dateMonth }} </span>
          <span v-else-if="hideTime"> {{ newDate | date }} </span>
          <span v-else> {{ newDate | dateTime }} </span>
        </slot>
      </v-card-title>

      <v-card-text>
        <template v-if="isMultiple">
          <v-date-picker
            v-if="hideTime && solo"
            v-model="dates"
            locale="jp-ja"
            :day-format="(dateVal) => new Date(dateVal).getDate()"
            range
            full-width
          />

          <v-stepper v-else v-model="step" outlined>
            <v-stepper-header class="elevation-0">
              <v-stepper-step step="1" editable>
                <span>開始</span>
                <span>{{ labelFilter(newDates[0]) }}</span>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="2" editable>
                <span>終了</span>
                <span>{{ labelFilter(newDates[1]) }}</span>
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <Picker
                  :mode="mode"
                  :hideTime="hideTime"
                  :month.sync="monthDates[0]"
                  :date.sync="dates[0]"
                  :time.sync="times[0]"
                  @commit="onChange"
                />
              </v-stepper-content>

              <v-stepper-content step="2">
                <Picker
                  :mode="mode"
                  :hideTime="hideTime"
                  :month.sync="monthDates[1]"
                  :date.sync="dates[1]"
                  :time.sync="times[1]"
                  @commit="onChange"
                />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </template>
        <template v-else>
          <Picker :mode="mode" :hideTime="hideTime" :month.sync="monthDate" :date.sync="date" :time.sync="time" @commit="onChange" />
        </template>
      </v-card-text>

      <v-card-actions>
        <v-btn color="warning" text @click="onCanceClicked"> キャンセル </v-btn>
        <v-spacer />

        <v-btn color="success" :disabled="!canCommit" @click="onCommitClicked" ripple> 決定 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Picker from './Picker.vue';

import { isDate } from '@/util/valid';

export default {
  components: { Picker },

  model: {
    prop: 'val',
    event: 'commit',
  },
  props: {
    label: {
      type: String,
      default: () => 'DateTimePicker',
    },

    val: {
      type: [Date, String, Array],
      default: () => new Date(),
    },

    hideTime: {
      type: Boolean,
      default: () => false,
    },
    solo: {
      type: Boolean,
      default: () => false,
    },

    month: {
      type: Boolean,
      default: () => false,
    },

    // 選択時にコミットさせダイアログも閉じる設定か？
    auto: {
      type: Boolean,
      default: () => false,
    },
  },

  computed: {
    mode() {
      if (this.month) return 'month';
      return null;
    },
    isMonthMode() {
      return this.mode == 'month';
    },
    width() {
      if (this.isMultiple) {
        if (this.hideTime || this.month) return 375;
        if (this.$vuetify.breakpoint.smAndDown) return 375;
        return 700;
      } else {
        if (this.hideTime || this.month) return 325;
        if (this.$vuetify.breakpoint.smAndDown) return 325;
        return 650;
      }
    },

    inputedValue: {
      get() {
        return this.val;
      },
      set(newValue) {
        this.$emit('commit', newValue);
      },
    },

    newDate() {
      //
      return this.convertNew(this.monthDate, this.date, this.time);
    },

    newDates() {
      //
      if (!this.isMultiple) return null;
      // inputedValueのループ分展開したいのでこれを使う
      return this.inputedValue.map((a, i) => this.convertNew(this.monthDates[i], this.dates[i], this.times[i]));
    },

    canCommit() {
      if (this.isMultiple) {
        return this.newDates.filter((a) => !isDate(a)).length == 0;
      } else {
        return isDate(this.newDate);
      }
    },

    isMultiple() {
      return Array.isArray(this.inputedValue);
    },
  },

  data: () => ({
    dialog: false,
    step: 1,

    monthDate: null,
    date: null,
    time: null,

    monthDates: [],
    dates: [],
    times: [],
  }),
  methods: {
    init() {
      this.step = 1;
      //
      this.monthDate = null;
      this.date = null;
      this.time = null;

      this.monthDates = [];
      this.dates = [];
      this.times = [];
    },
    onLoaded() {
      this.init();

      try {
        if (this.isMultiple) {
          for (let val of this.inputedValue) {
            let data = this.convert(val);
            this.monthDates.push(data.monthDate);
            this.dates.push(data.date);
            this.times.push(data.time);
          }
        } else {
          let data = this.convert(this.inputedValue);
          this.monthDate = data.monthDate;
          this.date = data.date;
          this.time = data.time;
        }
      } catch (ex) {
        console.error('datetime ex', ex);
      }
    },

    onChange(mode) {
      //
      if (this.isMultiple) {
        if (this.step == 1) {
          if ((this.hideTime && mode == 'date') || (this.month && mode == 'month') || mode == 'time') this.step++;
        } else if (this.step == 2) {
          if ((this.hideTime && mode == 'date') || (this.month && mode == 'month') || mode == 'time') {
            // 選択完了時にコミットさせるか？
            if (this.auto) this.onCommitClicked();
          }
        }
      } else {
        if ((this.hideTime && mode == 'date') || (this.month && mode == 'month') || mode == 'time') {
          // 選択完了時にコミットさせるか？
          if (this.auto) this.onCommitClicked();
        }
      }
    },

    onCanceClicked() {
      this.dialog = false;
    },

    onCommitClicked() {
      if (this.isMultiple) {
        console.log('onCommitClicked isMultiple newDates', this.newDates);
        this.inputedValue = this.newDates;
      } else {
        // this.$emit('commit',this.newDate);と同じ
        this.inputedValue = this.newDate;
      }
      this.dialog = false;
    },

    convert(val) {
      let data = {
        monthDate: null,
        date: null,
        time: null,
      };
      if (this.month) {
        let d2 = this.$moment(val).toDate();
        // FIXME Locateの指定をprop化し正しく挙動するようにすること
        let monthDate = new Date(d2 - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);

        data.monthDate = monthDate;
      } else {
        if (isDate(val)) {
          data.date = this.$moment(val).format('YYYY-MM-DD');
          data.time = this.$moment(val).format('HH:mm');
        }
      }

      return data;
    },

    convertNew(monthDate, date, time) {
      //
      if (this.month) {
        if (!isDate(monthDate)) return null;
        //
        let date = this.$moment(`${monthDate}`).toDate();
        return date;
      } else {
        if (!isDate(date)) return null;

        let date2 = this.$moment(`${date}`).toDate();
        if (time != null) {
          date2 = this.$moment(`${date} ${time}`).toDate();
        }
        return date2;
      }
    },

    labelFilter(val) {
      if (this.hideTime) return this.$options.filters.date(val);
      if (this.month) return this.$options.filters.dateMonth(val);

      return this.$options.filters.dateTime(val);
    },
  },

  watch: {
    dialog(val) {
      if (val) this.onLoaded();
    },
  },

  // mountedでは機能しない
  created() {
    // this.onLoaded();
  },
};
</script>
