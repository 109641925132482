import _ from 'lodash'

export const jsonToForm = (params, formData, name = '') => {
  if (_.isArray(params)) formatArray(params, formData, name);
  if (_.isPlainObject(params)) formatObject(params, formData, name);
  return formData;
}

export const formatObject = (params, formData, name) => {
  _.forEach(params, (v, k) => {
    if (_.isArray(v) || _.isPlainObject(v)) {
      jsonToForm(v, formData, !name ? k : `${name}.${k}`);
      return;
    }
    // nullの場合は送らない
    if (v == null) return;
    //
    formData.append(!name ? k : `${name}.${k}`, v);
  })
}

export const formatArray = (params, formData, name) => {
  _.map(params, (data, index) => {
    if (_.isArray(data) || _.isPlainObject(data)) {
      jsonToForm(data, formData, `${name}[${index}]`);
      return;
    }
    formData.append(`${name}[${index}]`, data);
  });
  return formData;
}



export function toFormData(original, data) {
  // const data = new FormData() // これに値を突っ込んでいく

  // 再帰関数
  function setData(path, obj) {
    _.each(obj, (val, key) => {
      let nextPath = path.length > 0 ? path + '[' + key + ']' : key
      if (_.isArray(obj)) {
        nextPath = path + '[]'
      }
      if (val instanceof File) {
        data.append(nextPath, val) // Fileは突っ込む
        return
      }
      if (_.isObject(val)) {
        // File以外のオブジェクト（配列含む）だったら再帰
        setData(nextPath, val)
        return
      }
      data.append(nextPath, val) // 突っ込む
    })
  }

  // 再帰関数を起動して、値を突っ込まれたFormDataを返す
  setData('', original)
  return data
}