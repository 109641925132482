<template>
  <v-tooltip color="warning" bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon color="warning" v-text="'mdi-hand-back-right-outline'" small v-bind="attrs" v-on="on" />
    </template>
    <span>修正済み</span>
  </v-tooltip>
</template>

<script>
export default {
  components: {},
  props: {},
  computed: {},

  data: () => ({}),

  methods: {
    onLoaded() {},
  },
  watch: {},
  mounted() {
    this.onLoaded();
  },
};
</script>