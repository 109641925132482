import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import store from '@/store';

import Mixin from '@/mixins/mixin';

// 設定ファイル読み込み
import webconfig from '@/web-configs';

const routes = [
  {
    path: '/',
    name: '',
    // redirect: '/Home',
    redirect: '/Signin',
    component: () => import('@/containers/TheContainer.vue'),
    children: [
      {
        path: 'Home',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
        meta: {
          title: 'Home',
          description: '',
          keywords: [],
          requireAuth: true,
          roles: [],
          authorities: [],
        },
      },

      {
        path: 'Forms/:form/:kokyaku_code?',
        name: 'Forms',
        // redirect: 'Forms/dashboard',
        component: () => import('@/containers/forms/TheContainer.vue'),
        meta: {
          title: 'フォーム関連',
          description: '',
          keywords: [],
          requireAuth: true,
          roles: [],
          authorities: [],
        },
        children: [],
      }, // end forms

      {
        path: 'users/dashboard',
        name: 'Users',
        component: () => import('@/views/users/dashboard.vue'),
        meta: {
          title: '',
          description: '',
          keywords: [],
          requireAuth: true,
          roles: [],
          authorities: [],
        },
      },
      {
        path: 'users/record',
        name: 'Users.record',
        component: () => import('@/views/users/records/Record.vue'),
        meta: {
          title: '',
          description: '',
          keywords: [],
          requireAuth: true,
          roles: [],
          authorities: [],
        },
      },

      {
        path: 'managements',
        name: 'managements',
        redirect: 'managements/dashboard',
        component: () => import('@/containers/managements/TheContainer.vue'),
        children: [
          {
            path: 'dashboard',
            name: 'managements.dashboard',
            component: () => import('@/views/managements/dashboard.vue'),
            meta: {
              title: 'managements',
              description: '',
              keywords: [],
              requireAuth: true,
              roles: [],
              authorities: [],
            },
          },

          {
            path: 'data',
            name: 'managements.data',
            component: () => import('@/views/managements/datas/data.vue'),
            meta: {
              title: 'managements.data',
              description: '',
              keywords: [],
              requireAuth: true,
              roles: [],
              authorities: [],
            },
          },

          {
            path: 'flow/:disp?',
            name: 'managements.flow',
            component: () => import('@/views/managements/flow/flow.vue'),
            meta: {
              title: 'managements.flow',
              description: '',
              keywords: [],
              requireAuth: true,
              roles: [],
              authorities: [],
            },
          },

          {
            path: 'facility',
            name: 'managements.facility',
            component: () => import('@/views/managements/facility.vue'),
            meta: {
              title: 'managements.facility',
              description: '',
              keywords: [],
              requireAuth: true,
              roles: [],
              authorities: [],
            },
          },

          {
            path: 'analyse',
            name: 'managements.analyse',
            component: () => import('@/views/managements/analyses/analyse.vue'),
            meta: {
              title: 'analyse',
              description: '',
              keywords: [],
              requireAuth: true,
              roles: [],
              authorities: [],
            },
          },

          {
            // analyseと同じコンポーネント(pathも同じだとメニューのハイライトが同じになるので変更)
            path: 'analyseTerm',
            name: 'managements.analyseTerm',
            component: () => import('@/views/managements/analyses/analyse.vue'),
            meta: {
              title: 'analyseTerm',
              description: '',
              keywords: [],
              requireAuth: true,
              roles: [],
              authorities: [],
            },
          },

          {
            path: 'point',
            name: 'managements.point',
            component: () => import('@/views/managements/points/Point.vue'),
            meta: {
              title: 'point',
              description: '',
              keywords: [],
              requireAuth: true,
              roles: [],
              authorities: [],
            },
          },

          {
            path: 'mergin',
            name: 'managements.mergin',
            component: () => import('@/views/managements/mergins/Mergin.vue'),
            meta: {
              title: 'mergin',
              description: '',
              keywords: [],
              requireAuth: true,
              roles: [],
              authorities: [],
            },
          },

          {
            path: 'sales',
            name: 'managements.sales',
            component: () => import('@/views/managements/sales/sales.vue'),
            meta: {
              title: 'sales',
              description: '',
              keywords: [],
              requireAuth: true,
              roles: [],
              authorities: [],
            },
          },

          {
            path: 'schedule',
            name: 'managements.schedule',
            component: () => import('@/views/managements/schedules/schedule.vue'),
            meta: {
              title: 'schedule',
              description: '',
              keywords: [],
              requireAuth: true,
              roles: [],
              authorities: [],
            },
          },
        ],
      },

      {
        path: 'Settings',
        name: 'Settings',
        redirect: 'Settings/dashboard',
        component: () => import('@/containers/settings/TheContainer.vue'),
        children: [
          {
            path: 'dashboard',
            name: 'settings.dashboard',
            component: () => import('@/views/settings/dashboard.vue'),
            meta: {
              title: '設定',
              description: '',
              keywords: [],
              requireAuth: true,
              roles: [],
              authorities: [],
            },
          },
        ],
      }, // end Settings

      {
        path: 'kokyakus',
        name: 'kokyakus',
        component: () => import('@/views/kokyakus/dashboard.vue'),
        meta: {
          title: '顧客管理',
          description: '',
          keywords: [],
          requireAuth: true,
          roles: [],
          authorities: [],
        },
      }, // end Kokyakus

      {
        path: '/release',
        name: 'Release',
        component: () => import('@/views/releases/Release.vue'),
        meta: {
          title: 'リリースノート',
          description: '',
          keywords: [],
          requireAuth: true,
          roles: [],
          authorities: [],
        },
      },

      {
        path: '/help',
        name: 'Help',
        component: () => import('@/views/helps/Help.vue'),
        meta: {
          title: 'ヘルプ',
          description: '',
          keywords: [],
          requireAuth: true,
          roles: [],
          authorities: [],
        },
      },
    ],
  },

  {
    path: '/Signout',
    name: 'signout',
    component: () => import('@/views/Signout.vue'),
    meta: {
      title: 'サインアウト',
      description: '',
      keywords: [],
      requireAuth: false,
      roles: [],
      authorities: [],
    },
  },

  {
    path: '/Signin/:state?',
    name: 'signin',
    component: () => import('@/views/signins/Signin.vue'),
    meta: {
      title: 'サインイン',
      description: '',
      keywords: [],
      requireAuth: false,
      roles: [],
      authorities: [],
    },
  },

  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue'),
    meta: {
      title: 'about',
      description: '',
      keywords: [],
      requireAuth: true,
      roles: [],
      authorities: [],
    },
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404.vue'),
    meta: {
      title: '404',
      description: '',
      keywords: [],
      requireAuth: false,
      roles: [],
      authorities: [],
    },
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
  base: webconfig.application.base,
  linkActiveClass: 'active',
  scrollBehavior: () => ({
    y: 0,
  }),
});

// ログインチェック
let checkLoginAsync = async function () {
  try {
    // ライセンスチェック
    {
      let success = await Mixin.methods.get(webconfig.api.license, {});
      let data = success.data;
      console.log('license data', data);

      let api = {
        license: data.license,
      };
      store.commit('setApi', api);
    }

    // ログインチェックは常に行う
    let user = store.getters.user;

    let code = null;
    let email = null;

    if (user != null) {
      code = user.code;
      email = user.email;
    }

    let success = await Mixin.methods.get('auth/user', {
      code: code,
      email: email,
    });

    let data = success.data;
    let u = {
      code: data.code,
      name: data.name,
      email: data.email,
      teams: data.teams,
      team: data.team,
      branch: data.branch,
      branchs: data.branchs,
      avatar: data.avatar,
      authorityTypes: data.authorityTypes,
    };

    //ユーザ更新 トークン更新はしない
    store.commit('setUser', u);
  } catch (ex) {
    //ユーザ情報削除
    store.commit('clearUser');
  }
};
//
router.beforeResolve(async (to, from, next) => {
  // サインインが必要なページアクセスか？
  let requireAuth = to.matched.some((record) => record.meta.requireAuth);

  // ページ移動時成功時のフラグは下げておく
  store.commit('setFormChanged', false);

  //ログインチェックは常に行う
  await checkLoginAsync();

  // // ログインしていなければサインアウトページに移動（さらにログインページに移動）
  if (requireAuth && !store.getters.user.isLogined) {
    router.push({ path: '/Signout' }).catch((e) => {
      console.error('sign out push error.', e);
    });
    return;
  } else {
    // 通常ページ移動
    return next();
  }
});

export default router;
