<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <div class="text-overline">アポ情報</div>

              <span>
                <XChipDummy v-if="apo.facility.isDummy" class="mr-2" />
                {{ apo.facility.name }}</span
              >
              <span v-if="apo.facility.pref != null" class="ml-2 text-caption">
                ({{ apo.facility.pref.name }}{{ apo.facility.areaName }}

                <a :href="`tel:${apo.facility.tel}`" v-text="apo.facility.tel" />)
              </span>
            </v-list-item-title>
            <v-list-item-subtitle>
              <span>アポ日{{ apo.date | date }} </span>
              <span>デモ日程{{ apo.demoSchedule | dateTime }} </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <span>{{ $options.filters.demo_naiyou(apo.demoItemType) }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <!--         
        <span>
          <span>{{ apo.facility.name }}</span>
          <span v-if="apo.facility.pref != null">({{ apo.facility.pref.name }}{{ apo.facility.areaName }}</span>
          <a :href="`tel:${apo.facility.tel}`" v-text="apo.facility.tel" />)
        </span>
        <span class="ml-4">{{ $options.filters.demo_naiyou(apo.demoItemType) }}</span>
        <v-spacer />
        <span>アポ日{{ apo.date | date }} </span>
        <span>デモ日程{{ apo.demoSchedule | dateTime }} </span> -->
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="3">アポ種別</v-col>
              <v-col cols="8">{{ $options.filters.apoType(apo.apoType) }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="3">アポ担当</v-col>
              <v-col cols="8">
                <span v-if="apo.apointer != null">{{ apo.apointer.name }}</span>
                <span v-if="apo.branch != null">({{ apo.branch.name }})</span>
                <span v-if="apo.team != null">({{ apo.team.name }})</span>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3">デモ対象</v-col>
              <v-col cols="8">{{ apo.demoTarget }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="3">デモ日程</v-col>
              <v-col cols="8">
                {{ apo.demoSchedule | dateTime }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">備考</v-col>
              <v-col cols="8" v-html="$options.filters.md(apo.memo)"></v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="text-caption">{{ apo.code }}</v-card-actions>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {
    apo: {
      type: Object,
      required: true,
    },
  },
  computed: {},
  data: () => ({}),
  methods: {},
  watch: {},
  mounted() {
    // this.onLoaded();
  },
};
</script>