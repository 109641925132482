 <template>
  <span><v-icon v-if="value" v-text="'mdi-check-circle'" color="success" /><slot></slot>{{ label }}</span>
</template>


<script>
export default {
  components: {},

  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
    label: {
      type: String,
      default: () => null,
    },
  },

  computed: {},
};
</script>
<style scoped>
</style>