export const Icons = {
  form: 'mdi-email-multiple-outline',
  flow: 'mdi-chart-timeline-variant',
  facility: 'mdi-bed',
  master: 'mdi-account-details',

  apo: 'mdi-ceiling-light-outline',
  demo: 'mdi-account-tie-outline',
  leasePayment: 'mdi-file-check-outline',
  hpCoverage: 'camera-outline-outline',
  hpCreate: 'mdi-creation',

  analyse: 'mdi-chart-line',
  termAnalyse: 'mdi-stack-overflow',

  schedule: 'mdi-progress-clock',
};

export const LeasePaymentStates = {
  /// <summary>リース会社提出待ち</summary>
  Default: 1,
  /// <summary>リース会社提出(審査中)</summary>
  Examination: 1 << 1,
  /// <summary>結果OK</summary>
  OK: 1 << 2,
  /// <summary>結果NG</summary>
  NG: 1 << 3,
  /// 結果全NG
  AllNG: 1 << 4,

  /// <summary>事前審査中</summary>
  PreExamination: 1 << 5,
  /// <summary>事前審査OK</summary>
  PreExaminationOK: 1 << 6,
  /// <summary>事前審査NG</summary>
  PreExaminationNG: 1 << 7,
};
export const LeasePaymentStartStates = {
  UnCollected: 1, // 未回収
  Collected: 1 << 1, // 回収OK
  Submited: 1 << 2, // 提出OK
  StartOK: 1 << 3, // 開始OK
  StartNG: 1 << 4, // その開始NG他
  CompleteOK: 1 << 5, // 入金OK
  CompleteNG: 1 << 6, // 入金NG
  Rental: 1 << 7, // レンタル

  NotStart: 1 << 10, // 未開始(これはWebAPIのDemoResultTypesで定義されているものではありません)
  NotComplete: 1 << 11, // 入金NG等(これはWebAPIのDemoResultTypesで定義されているものではありません)
  Edit: 1 << 12, // リース関係情報修正(これはWebAPIのDemoResultTypesで定義されているものではありません)
};

export const ApoStates = {
  Default: 1, // デモ日程確認待ち
  DemoScheduleConfirmed: 1 << 1, // デモ日程確認済み
  Cancelled: 1 << 5, // キャンセル済み

  Missed: 1 << 19, // WebAPI定義外
};

export const DemoResultTypes = {
  Default: 1, // 未デモ
  OK: 1 << 1, // OK
  NG: 1 << 2, // NG
  Wait: 1 << 3, // 返事待ち
  Cancelled: 1 << 4, // キャンセル
  Other: 1 << 5, // その他
  ReDemo: 1 << 9, // 再デモ

  UnHPCoverageScheduled: 1 << 8, // 取材日未設定(これはWebAPIのDemoResultTypesで定義されているものではありません)

  PreExamination: 1 << 10, // 事前審査所未提出(これはWebAPIのDemoResultTypesで定義されているものではありません)
  Examination: 1 << 11, // 事前審査所提出(これはWebAPIのDemoResultTypesで定義されているものではありません)

  NoInvoiced: 1 << 15, // 請求書未発行 WebAPI定義外
  NoDeposited: 1 << 16, // 入金待ち WebAPI定義外
  Deposited: 1 << 17, // 入金済み WebAPI定義外

  Missed: 1 << 19, // WebAPI定義外
};

export const FilterDisps = {
  All: 1,
  FacilityName: 1 << 1,
  ApoBranch: 1 << 2,
  DemoBranch: 1 << 3,
  ApoDates: 1 << 4,
  DemoDates: 1 << 5,
  hpCoverageScheduleDates: 1 << 6,
  HpCoverageDates: 1 << 7,
};

export const LeasePaymentCompanyTypes = {
  Orix: 1, // オリックス
  CreditSaison: 1 << 1, // クレディセゾン
  Smbc: 1 << 2, // 三井住友ファイナンス
  Others: 1 << 5, // その他
};

/// <summary>
/// あすなろプラス種別
/// </summary>
export const AsunaroPlusTypes = {
  /// <summary>オーダー</summary>
  Order: 1,
  /// <summary>キッチン</summary>
  Kitchen: 1 << 1,
};

/// <summary>
/// 宿プラス種別
/// </summary>
export const YadoPlusTypes = {
  /// <summary>良選企画</summary>
  Ryosenkikaku: 1,
};

/// <summary>
/// チームマーキング
/// 特別な用途がある場合いずれのマークがついているかで場合分け
/// </summary>
export const TeamMarkTypes = {
  /// <summary>「支店長チーム」という特別な用途</summary>
  Leader: 1,
};
