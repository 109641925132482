<template>
  <v-chip color="warning" small> <v-icon v-text="'mdi-close-octagon-outline'" />{{ label }} </v-chip>
</template>

<script>
export default {
  components: {},
  props: {
    label: {
      type: String,
      default: () => '',
    },
  },
  computed: {},

  data: () => ({}),

  methods: {
    onLoaded() {},
  },
  watch: {},
  mounted() {
    this.onLoaded();
  },
};
</script>