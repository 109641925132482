<template>
  <!-- <v-fade-transition> -->
  <v-sheet v-if="loading" class="text-center" :min-width="minWidth" :max-width="maxWidth" :min-height="minHeight" :max-height="maxHeight">
    <v-progress-circular v-if="type == 'circular'" indeterminate :color="color"></v-progress-circular>

    <div v-else-if="type == 'linear'">
      <v-progress-linear indeterminate :color="color"></v-progress-linear>
    </div>

    <slot name="label"></slot>
  </v-sheet>
  <v-sheet v-else :min-width="minWidth" :max-width="maxWidth" :min-height="minHeight" :max-height="maxHeight">
    <slot :loaded="!loading"></slot>
  </v-sheet>
  <!-- </v-fade-transition> -->
</template>
<script>
export default {
  model: {},

  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },

    type: {
      type: String,
      default: () => 'circular',
    },

    color: {
      type: String,
      default: () => 'primary',
    },

    minWidth: {
      type: [Number, String],
      default: () => 50,
    },

    maxWidth: {
      type: [Number, String],
      default: () => null,
    },

    minHeight: {
      type: [Number, String],
      default: () => 50,
    },
    maxHeight: {
      type: [Number, String],
      default: () => null,
    },
  },

  computed: {},
  data: () => ({}),

  methods: {},

  watch: {},
  created() {},
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>