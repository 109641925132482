<template>
  <v-tooltip color="purple" bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon color="purple" v-text="'mdi-weather-pouring'" v-bind="attrs" v-on="on" />
    </template>
    <span>入力ミス</span>
  </v-tooltip>
</template>

<script>
export default {
  components: {},
  props: {},
  computed: {},

  data: () => ({}),

  methods: {
    onLoaded() {},
  },
  watch: {},
  mounted() {
    this.onLoaded();
  },
};
</script>