<template>
  <v-card flat>
    <!-- 間を持たせるためそれっぽいローディングを見せる-->
    <!-- <v-overlay absolute :value="loading.step < 100 || initializing">
      <v-card min-width="250" min-height="50">
        <v-card-title class="text-caption pa-2 white-text">
          <v-fade-transition>
            <span v-if="loading.step < 5">loading...</span>
            <span v-else-if="loading.step < 20">支店データを適用します...</span>
            <span v-else-if="loading.step < 25">チームを展開し解析開始します...</span>
            <span v-else-if="loading.step < 30">取得したデータを展開し分析をはじめます...</span>
            <span v-else-if="loading.step < 35"
              ><v-icon color="purple darken-1" v-text="'mdi-flask-empty-outline'" left />パターン分析開始...</span
            >
            <span v-else-if="loading.step < 55"><v-icon color="cyan lighten-3" v-text="'mdi-flare'" left />パターン分析中...</span>
            <span v-else-if="loading.step < 75"
              ><v-icon color="green lighten-1" v-text="'mdi-television-shimmer'" left />分析終了処理中...</span
            >
            <span v-else-if="loading.step < 80">チームの補正データを適用します...</span>
            <span v-else-if="loading.step < 85">すけさん売上を週順に展開中...</span>
            <span v-else-if="loading.step < 91">デモ内訳を展開しています...</span>
            <span v-else>表示準備中...</span>
          </v-fade-transition>
          <v-spacer />

          <v-fade-transition>
            <v-progress-circular v-if="loading.step < 90" indeterminate color="primary" size="15" width="3" />
          </v-fade-transition>
        </v-card-title>
        <v-card-text class="pa-2">
          <v-progress-linear color="cyan" :value="(loading.step / 100) * 100" height="20">
            <strong>{{ Math.ceil((loading.step / 100) * 100 * 10) / 10 }}%</strong>
          </v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay> -->
    <v-card-text>
      <v-simple-table dense>
        <thead>
          <tr class="blue lighten-3">
            <th class="divider">&nbsp;</th>
            <template v-for="(d, j) in detailDatas">
              <th v-if="d.teamName != null" :key="`${j}_tc`" :colspan="dataTitlesCount(d.dataTitles)" class="divider text-center">
                {{ d.teamName }}
              </th>
              <th v-if="d.date != null" :key="`${j}_month`" :colspan="dataTitlesCount(d.dataTitles)" class="divider text-center">
                <!-- {{ d.date.getMonth() }}月 -->
                {{ d.date.getFullYear() }} /{{ d.date.getMonth() + 1 }}
              </th>
            </template>
          </tr>
          <tr class="blue lighten-3">
            <template v-for="(d, j) of detailDatas">
              <th v-if="j == 0" :key="`${j}_title`" class="divider">&nbsp;</th>
              <th v-if="d.dataTitles.quotasBeforeNow" :key="`${j}_1`" nowrap>目標(予算:当月までの累計)</th>
              <th v-if="d.dataTitles.quotas" :key="`${j}_2`" nowrap>目標(予算)</th>
              <th v-if="d.dataTitles.prospectAmount2" :key="`${j}_3`" nowrap>売上見込み</th>
              <th v-if="d.dataTitles.prospectPercent2" :key="`${j}_4`" nowrap>達成率</th>
              <th v-if="d.dataTitles.amount" :key="`${j}_5`" nowrap>売上実績</th>
              <th v-if="d.dataTitles.percent" :key="`${j}_6`" nowrap class="divider">達成率</th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(type, index) of (sectionType & (1 << 4)) != 0
              ? [
                  AnalyseSaleTypes.Stay,
                  AnalyseSaleTypes.EatingAndDrinking,
                  AnalyseSaleTypes.DayStay,
                  AnalyseSaleTypes.Store,
                  AnalyseSaleTypes.Other,
                  AnalyseSaleTypes.ReturnBack,
                  AnalyseSaleTypes.Discount,
                ]
              : [
                  AnalyseSaleTypes.SystemNew,
                  AnalyseSaleTypes.SystemReplace,
                  AnalyseSaleTypes.Sukesan,
                  AnalyseSaleTypes.YadoPlus,
                  AnalyseSaleTypes.Rental,
                  AnalyseSaleTypes.Web,
                  AnalyseSaleTypes.Other,
                ]"
          >
            <tr :key="`table2-row${index}-${branchCode}-${teamCode}`" v-if="isVisibled(type, 'quotas')">
              <!-- prospectAmount2,prospectPercent2:レンタル・Web・その他の場合のみ売上見込みを売上実績で「見た目上表示」（実際データは見込みではありません)させる -->

              <template v-for="(d, j) of detailDatas">
                <td v-if="j == 0" :key="`${j}_body_a`" class="divider" nowrap>
                  {{ getAnalyseSaleTypeLabel(type) }}
                </td>

                <td v-if="d.dataTitles.quotasBeforeNow" :key="`${j}_body_bb`">
                  {{ analyseData(datas, d.date, type, branchCode, d.teamCode, null, sectionType).quotasBeforeNow | priceJP }}
                </td>
                <td v-if="d.dataTitles.quotas" :key="`${j}_body_b`">
                  {{ analyseData(datas, d.date, type, branchCode, d.teamCode, null, sectionType).quotas | priceJP }}
                </td>
                <td v-if="d.dataTitles.prospectAmount2" :key="`${j}_body_c`">
                  {{ analyseData(datas, d.date, type, branchCode, d.teamCode, null, sectionType).prospectAmount2 | priceJP }}
                </td>
                <td v-if="d.dataTitles.prospectPercent2" :key="`${j}_body_d`">
                  {{ analyseData(datas, d.date, type, branchCode, d.teamCode, null, sectionType).prospectPercent2 | percent }}%
                </td>
                <td v-if="d.dataTitles.amount" :key="`${j}_body_e`">
                  {{ analyseData(datas, d.date, type, branchCode, d.teamCode, null, sectionType).amount | priceJP }}
                </td>
                <td v-if="d.dataTitles.percent" :key="`${j}_body_f`" class="divider">
                  {{ analyseData(datas, d.date, type, branchCode, d.teamCode, null, sectionType).percent | percent }}%
                </td>
              </template>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <tr class="bottom-top">
            <template v-for="(d, j) in detailDatas">
              <th v-if="j == 0" class="divider" :key="`${j}_foot1_a`">小計</th>

              <th v-if="d.dataTitles.quotasBeforeNow" :key="`${j}_foot1_bb`">
                {{ analyseData(datas, d.date, null, branchCode, d.teamCode, null, sectionType).quotasBeforeNow | priceJP }}
              </th>
              <th v-if="d.dataTitles.quotas" :key="`${j}_foot1_b`">
                {{ analyseData(datas, d.date, null, branchCode, d.teamCode, null, sectionType).quotas | priceJP }}
              </th>
              <th v-if="d.dataTitles.prospectAmount2" :key="`${j}_foot1_c`">
                {{ analyseData(datas, d.date, null, branchCode, d.teamCode, null, sectionType).prospectAmount2 | priceJP }}
              </th>
              <th v-if="d.dataTitles.prospectPercent2" :key="`${j}_foot1_d`">
                {{ analyseData(datas, getDate(j), null, branchCode, d.teamCode, null, sectionType).prospectPercent2 | percent }}%
              </th>
              <th v-if="d.dataTitles.amount" :key="`${j}_foot1_e`">
                {{ analyseData(datas, d.date, null, branchCode, d.teamCode, null, sectionType).amount | priceJP }}
              </th>
              <th v-if="d.dataTitles.percent" :key="`${j}_foot1_f`" class="divider">
                {{ analyseData(datas, d.date, null, branchCode, d.teamCode, null, sectionType).percent | percent }}%
              </th>
            </template>
          </tr>

          <!-- 補正予算がなければ表示しない-->
          <template v-if="analyseData(datas, null, AnalyseSaleTypes.CorrectedTotal, branchCode, teamCode, null, sectionType).quotas > 0">
            <!-- 実際に表示するのは「補正値を加味した小計」 -->
            <tr class="bottom-top">
              <template v-for="(d, j) in detailDatas">
                <th v-if="j == 0" class="divider" :key="`${j}_foot2_a`">小計(補正)</th>

                <th v-if="d.dataTitles.quotasBeforeNow" :key="`${j}_foot2_bb`">
                  {{
                    analyseData(datas, d.date, AnalyseSaleTypes.ResultTotal, branchCode, d.teamCode, null, sectionType).quotasBeforeNow
                      | priceJP
                  }}
                </th>
                <th v-if="d.dataTitles.quotas" :key="`${j}_foot2_b`">
                  {{ analyseData(datas, d.date, AnalyseSaleTypes.ResultTotal, branchCode, d.teamCode, null, sectionType).quotas | priceJP }}
                </th>
                <th v-if="d.dataTitles.prospectAmount2" :key="`${j}_foot2_c`">
                  {{ analyseData(datas, d.date, null, branchCode, d.teamCode, null, sectionType).prospectAmount2 | priceJP }}
                </th>
                <th v-if="d.dataTitles.prospectPercent2" :key="`${j}_foot2_d`">
                  {{
                    (analyseData(datas, d.date, null, branchCode, d.teamCode, null, sectionType).prospectAmount2 /
                      analyseData(datas, d.date, AnalyseSaleTypes.ResultTotal, branchCode, d.teamCode, null, sectionType).quotas)
                      | percent
                  }}%
                </th>
                <th v-if="d.dataTitles.amount" :key="`${j}_foot2_e`">
                  {{ analyseData(datas, d.date, null, branchCode, d.teamCode, null, sectionType).amount | priceJP }}
                </th>
                <th v-if="d.dataTitles.percent" class="divider" :key="`${j}_foot2_f`">
                  {{
                    (analyseData(datas, d.date, null, branchCode, d.teamCode, null, sectionType).amount /
                      analyseData(datas, d.date, AnalyseSaleTypes.ResultTotal, branchCode, d.teamCode, null, sectionType).quotas)
                      | percent
                  }}%
                </th>
              </template>
            </tr>
            <tr>
              <template v-for="(d, j) in detailDatas">
                <th v-if="j == 0" :key="`${j}_foot3_a`" class="divider">補正修正内容</th>
                <td :colspan="dataTitlesCount(d)" class="divider" :key="`${j}_foot3_b`">
                  {{
                    analyseData(datas, d.date, AnalyseSaleTypes.CorrectedTotal, branchCode, d.teamCode, null, sectionType).quotaMessages
                      | implode
                  }}
                </td>
              </template>
            </tr>
          </template>
        </tfoot>
      </v-simple-table>
    </v-card-text>

    <v-card-text v-if="sectionType == 1 << 4">
      <!-- <v-simple-table dense>
        <thead>
          <tr class="blue lighten-3">
            <th>&nbsp;</th>
            <th>目標(予算)</th>
            <th>売上見込み</th>
            <th>達成率</th>
            <th>売上実績</th>
            <th>達成率</th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(type, index) of [
              AnalyseSaleTypes.Stay,
              AnalyseSaleTypes.EatingAndDrinking,
              AnalyseSaleTypes.DayStay,
              AnalyseSaleTypes.Store,
              AnalyseSaleTypes.Other,
              AnalyseSaleTypes.ReturnBack,
              AnalyseSaleTypes.Discount,
            ]"
          >
            <tr :key="`table-row${index}-${branchCode}-${teamCode}`" v-if="isVisibled(type, 'quotas')">
              <template>
                <th>
                  {{ getAnalyseSaleTypeLabel(type) }}
                </th>
                <td>
                  {{ analyseData(datas, date, type, branchCode, teamCode, null, sectionType).quotas | priceJP }}
                </td>
                <td>
                  {{ analyseData(datas, date, type, branchCode, teamCode, null, sectionType).prospectAmount2 | priceJP }}
                </td>
                <td>{{ analyseData(datas, date, type, branchCode, teamCode, null, sectionType).prospectPercent2 | percent }}%</td>
                <td>
                  {{ analyseData(datas, date, type, branchCode, teamCode, null, sectionType).amount | priceJP }}
                </td>
                <td>{{ analyseData(datas, date, type, branchCode, teamCode, null, sectionType).percent | percent }}%</td>
              </template>
            </tr>
          </template>
        </tbody>

        <tfoot>
          <tr class="bottom-top">
            <th>小計</th>
            <th>{{ analyseData(datas, date, null, branchCode, teamCode, null, sectionType).quotas | priceJP }}</th>
            <th>{{ analyseData(datas, date, null, branchCode, teamCode, null, sectionType).prospectAmount2 | priceJP }}</th>
            <th>{{ analyseData(datas, date, null, branchCode, teamCode, null, sectionType).prospectPercent2 | percent }}%</th>
            <th>
              {{ analyseData(datas, date, null, branchCode, teamCode, null, sectionType).amount | priceJP }}
            </th>
            <th>{{ analyseData(datas, date, null, branchCode, teamCode, null, sectionType).percent | percent }}%</th>
          </tr>

          <template v-if="analyseData(datas, date, AnalyseSaleTypes.CorrectedTotal, branchCode, teamCode, null, sectionType).quotas > 0">

            <tr class="bottom-top">
              <th>小計(補正)</th>
              <th>
                {{ analyseData(datas, date, AnalyseSaleTypes.ResultTotal, branchCode, teamCode, null, sectionType).quotas | priceJP }}
              </th>
              <th>
                {{ analyseData(datas, date, null, branchCode, teamCode, null, sectionType).amount | priceJP }}
              </th>
              <th>
                {{
                  (analyseData(datas, date, null, branchCode, teamCode, null, sectionType).amount /
                    analyseData(datas, date, AnalyseSaleTypes.ResultTotal, branchCode, teamCode, null, sectionType).quotas)
                    | percent
                }}%
              </th>
            </tr>
            <tr>
              <th>補正修正内容</th>
              <td colspan="5">
                {{
                  analyseData(datas, date, AnalyseSaleTypes.CorrectedTotal, branchCode, teamCode, null, sectionType).quotaMessages | implode
                }}
              </td>
            </tr>
          </template>
        </tfoot>
      </v-simple-table> -->
    </v-card-text>

    <v-card-text v-else>
      <!-- <v-simple-table dense>
        <thead>
          <tr class="blue lighten-3">
            <th>&nbsp;</th>
            <th>目標(予算)</th>
            <th>売上見込み</th>
            <th>達成率</th>
            <th>売上実績</th>
            <th>達成率</th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(type, index) of [
              AnalyseSaleTypes.SystemNew,
              AnalyseSaleTypes.SystemReplace,
              AnalyseSaleTypes.Sukesan,
              AnalyseSaleTypes.YadoPlus,
              AnalyseSaleTypes.Rental,
              AnalyseSaleTypes.Web,
              AnalyseSaleTypes.Other,
            ]"
          >
            <tr v-if="isVisibled(type, 'quotas')" :key="`table-row${index}-${branchCode}-${teamCode}`">
              <template>
                <th>
                  {{ getAnalyseSaleTypeLabel(type) }}
                </th>
                <td>
                  {{ analyseData(datas, date, type, branchCode, teamCode, null, sectionType).quotas | priceJP }}
                </td>
                <td>
                  {{ analyseData(datas, date, type, branchCode, teamCode, null, sectionType).prospectAmount2 | priceJP }}
                </td>
                <td>{{ analyseData(datas, date, type, branchCode, teamCode, null, sectionType).prospectPercent2 | percent }}%</td>
                <td>
                  {{ analyseData(datas, date, type, branchCode, teamCode, null, sectionType).amount | priceJP }}
                </td>
                <td>{{ analyseData(datas, date, type, branchCode, teamCode, null, sectionType).percent | percent }}%</td>
              </template>
            </tr>
          </template>
        </tbody>

        <tfoot>
          <tr class="bottom-top">
            <th>小計</th>
            <th>{{ analyseData(datas, date, null, branchCode, teamCode, null, sectionType).quotas | priceJP }}</th>
            <th>{{ analyseData(datas, date, null, branchCode, teamCode, null, sectionType).prospectAmount2 | priceJP }}</th>
            <th>{{ analyseData(datas, date, null, branchCode, teamCode, null, sectionType).prospectPercent2 | percent }}%</th>
            <th>
              {{ analyseData(datas, date, null, branchCode, teamCode, null, sectionType).amount | priceJP }}
            </th>
            <th>{{ analyseData(datas, date, null, branchCode, teamCode, null, sectionType).percent | percent }}%</th>
          </tr>

          <template v-if="analyseData(datas, date, AnalyseSaleTypes.CorrectedTotal, branchCode, teamCode, null, sectionType).quotas > 0">
            <tr class="bottom-top">
              <th>小計(補正)</th>
              <th>
                {{ analyseData(datas, date, AnalyseSaleTypes.ResultTotal, branchCode, teamCode, null, sectionType).quotas | priceJP }}
              </th>
              <th>{{ analyseData(datas, date, null, branchCode, teamCode, null, sectionType).prospectAmount2 | priceJP }}</th>
              <th>
                {{
                  (analyseData(datas, date, null, branchCode, teamCode, null, sectionType).prospectAmount2 /
                    analyseData(datas, date, AnalyseSaleTypes.ResultTotal, branchCode, teamCode, null, sectionType).quotas)
                    | percent
                }}%
              </th>
              <th>
                {{ analyseData(datas, date, null, branchCode, teamCode, null, sectionType).amount | priceJP }}
              </th>
              <th>
                {{
                  (analyseData(datas, date, null, branchCode, teamCode, null, sectionType).amount /
                    analyseData(datas, date, AnalyseSaleTypes.ResultTotal, branchCode, teamCode, null, sectionType).quotas)
                    | percent
                }}%
              </th>
            </tr>
            <tr>
              <th>補正修正内容</th>
              <td colspan="5">
                {{
                  analyseData(datas, date, AnalyseSaleTypes.CorrectedTotal, branchCode, teamCode, null, sectionType).quotaMessages | implode
                }}
              </td>
            </tr>
          </template>
        </tfoot>
      </v-simple-table> -->

      <template v-if="!hideSukesan">
        <v-btn text class="ml-2 my-2" @click="expand1 = !expand1">
          <v-icon v-text="expand1 ? 'mdi-chevron-down' : 'mdi-chevron-up'" />
          <span class="text-h6">すけさん売上</span>
        </v-btn>
        <v-expand-transition>
          <v-simple-table v-show="expand1" dense>
            <thead>
              <tr class="blue lighten-4">
                <th rowspan="3">&nbsp;</th>
                <th colspan="2">当月予算</th>
                <th colspan="2">次月予算</th>
              </tr>
              <tr>
                <td colspan="2">
                  <!-- {{ analyseData(datas, date, AnalyseSaleTypes.Sukesan, branchCode, teamCode, null, sectionType).quotas | priceJP }} -->
                  {{ analyseSukesanData(sukesanDatas, date, null, null, teamCode).quotaSukesan | priceJP }}
                </td>
                <td colspan="2">
                  {{ analyseSukesanData(sukesanDatas, nextMonth, null, null, teamCode).quotaSukesan | priceJP }}
                </td>
              </tr>
              <tr class="blue lighten-4">
                <th>見込み</th>
                <th>達成率</th>
                <th>見込み</th>
                <th>達成率</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="weekInMonth in [1, 2, 3, 4, 5]" :key="weekInMonth">
                <th>第{{ weekInMonth }}週</th>

                <td>
                  {{ analyseSukesanData(sukesanDatas, date, weekInMonth, null, teamCode).charge | priceJP }}
                </td>
                <td>
                  <XProgressBar
                    :value="$options.filters.percent(analyseSukesanData(sukesanDatas, date, weekInMonth, null, teamCode).quotaSukesanPer)"
                  />
                </td>

                <td>
                  {{ analyseSukesanData(sukesanDatas, nextMonth, weekInMonth, null, teamCode).charge | priceJP }}
                </td>
                <td>
                  <XProgressBar
                    :value="
                      $options.filters.percent(analyseSukesanData(sukesanDatas, nextMonth, weekInMonth, null, teamCode).quotaSukesanPer)
                    "
                  />
                </td>
              </tr>

              <!-- <SukesanRow
                  label="第1週"
                  :datas1="analyseData(datas, date, AnalyseSaleTypes.Sukesan, branchCode, teamCode, [1], sectionType).weekInMonths"
                  :quotas1="analyseData(datas, date, AnalyseSaleTypes.Sukesan, branchCode, teamCode, null, sectionType).quotas"
                  :datas2="analyseData(datas, nextMonth, AnalyseSaleTypes.Sukesan, branchCode, teamCode, [1], sectionType).weekInMonths"
                  :quotas2="analyseData(datas, nextMonth, AnalyseSaleTypes.Sukesan, branchCode, teamCode, null, sectionType).quotas"
                />
                <SukesanRow
                  label="第2週"
                  :datas1="analyseData(datas, date, AnalyseSaleTypes.Sukesan, branchCode, teamCode, [1, 2], sectionType).weekInMonths"
                  :quotas1="analyseData(datas, date, AnalyseSaleTypes.Sukesan, branchCode, teamCode, null, sectionType).quotas"
                  :datas2="analyseData(datas, nextMonth, AnalyseSaleTypes.Sukesan, branchCode, teamCode, [1, 2], sectionType).weekInMonths"
                  :quotas2="analyseData(datas, nextMonth, AnalyseSaleTypes.Sukesan, branchCode, teamCode, null, sectionType).quotas"
                />
                <SukesanRow
                  label="第3週"
                  :datas1="analyseData(datas, date, AnalyseSaleTypes.Sukesan, branchCode, teamCode, [1, 2, 3], sectionType).weekInMonths"
                  :quotas1="analyseData(datas, date, AnalyseSaleTypes.Sukesan, branchCode, teamCode, null, sectionType).quotas"
                  :datas2="
                    analyseData(datas, nextMonth, AnalyseSaleTypes.Sukesan, branchCode, teamCode, [1, 2, 3], sectionType).weekInMonths
                  "
                  :quotas2="analyseData(datas, nextMonth, AnalyseSaleTypes.Sukesan, branchCode, teamCode, null, sectionType).quotas"
                />
                <SukesanRow
                  label="第4週"
                  :datas1="analyseData(datas, date, AnalyseSaleTypes.Sukesan, branchCode, teamCode, [1, 2, 3, 4], sectionType).weekInMonths"
                  :quotas1="analyseData(datas, date, AnalyseSaleTypes.Sukesan, branchCode, teamCode, null, sectionType).quotas"
                  :datas2="
                    analyseData(datas, nextMonth, AnalyseSaleTypes.Sukesan, branchCode, teamCode, [1, 2, 3, 4], sectionType).weekInMonths
                  "
                  :quotas2="analyseData(datas, nextMonth, AnalyseSaleTypes.Sukesan, branchCode, teamCode, null, sectionType).quotas"
                />
                <SukesanRow
                  label="第5週"
                  :datas1="
                    analyseData(datas, date, AnalyseSaleTypes.Sukesan, branchCode, teamCode, [1, 2, 3, 4, 5], sectionType).weekInMonths
                  "
                  :quotas1="analyseData(datas, date, AnalyseSaleTypes.Sukesan, branchCode, teamCode, null, sectionType).quotas"
                  :datas2="
                    analyseData(datas, nextMonth, AnalyseSaleTypes.Sukesan, branchCode, teamCode, [1, 2, 3, 4, 5], sectionType).weekInMonths
                  "
                  :quotas2="analyseData(datas, nextMonth, AnalyseSaleTypes.Sukesan, branchCode, teamCode, null, sectionType).quotas"
                /> -->
            </tbody>
          </v-simple-table>
        </v-expand-transition>
      </template>
      <template v-if="!hideDetailList">
        <div class="mt-8">
          <v-btn text class="ml-2 my-2" @click="expand2 = !expand2">
            <v-icon v-text="expand2 ? 'mdi-chevron-down' : 'mdi-chevron-up'" />
            <span class="text-h6">内訳</span>
          </v-btn>
          <v-expand-transition>
            <div v-show="expand2">
              <div v-if="analyseData(datas, date, null, null, null, null, sectionType) != null">
                <template v-for="(contractFilter, index) of [(a) => a.isNewContract, (a) => a.isReplaceContract]">
                  <v-data-table
                    class="ml-4"
                    :headers="[
                      { text: 'コード', value: 'code' },

                      { text: '物件名', value: 'facility.name' },

                      { text: 'アポ', value: 'apointer' },
                      { text: 'デモ', value: 'demointer' },
                      { text: 'リース月額', value: 'sales' },
                      { text: '料率', value: 'resultRate' },
                      { text: '買取他', value: 'residualBonds' },
                      { text: '売上金額', value: 'prospectAmount' },
                      { text: '開始確認', value: 'start' },
                    ]"
                    :items="analyseData(datas, date, null, branchCode, teamCode, null, sectionType).demos.filter(contractFilter)"
                    item-key="code"
                    :items-per-page="-1"
                    multi-sort
                    dense
                    :key="`table${index}`"
                  >
                    <template v-slot:top>
                      <p class="ml-2 my-2 text-h6">
                        <template v-if="index == 0">システム新規</template>
                        <template v-if="index == 1">システムリプレイス</template>
                      </p>
                    </template>

                    <template v-slot:item.code="{ item }">
                      <a
                        color="primary"
                        class="ma-2"
                        outlined
                        @click="
                          {
                            demoEditor.demoCode = item.code;
                            demoEditor.show = true;
                          }
                        "
                      >
                        {{ item.code | lessStr(5, '..') }}
                      </a>
                    </template>

                    <template v-slot:item.apointer="{ item }">
                      <span v-if="item.apointer != null">{{ item.apointer.name }}</span>
                      <span v-else-if="item.apo != null && item.apo.apointer != null">{{ item.apo.apointer.name }}</span>
                      <span v-else-if="item.apo != null && item.apo.branch != null">{{ item.apo.branch.name }}</span>
                    </template>
                    <template v-slot:item.demointer="{ item }">
                      <span v-if="item.demointer != null">{{ item.demointer.name }}</span>
                    </template>

                    <template v-slot:item.sales="{ item }">
                      <p>
                        {{ item.sales | priceJP }}<br />
                        <span class="text-caption"> ({{ item.saleType | demoSaleType }}) </span>
                      </p>
                    </template>

                    <template v-slot:item.residualBonds="{ item }">
                      {{ (item.residualBonds + item.underwritingBonds + item.complimentaryBonds) | priceJP }}
                    </template>

                    <template v-slot:item.prospectAmount="{ item }">
                      <v-btn
                        text
                        @click="
                          {
                            leasePaymentEditor.leasePaymentCode = item.leasePayment.code;
                            leasePaymentEditor.show = true;
                            leasePaymentEditor.viewStartState = LeasePaymentStartStates.Edit;
                          }
                        "
                      >
                        {{ item.prospectAmount | priceJP }}
                      </v-btn>
                    </template>

                    <template v-slot:item.start="{ item }">
                      <span>{{ getRowStartLabel(item) }}</span>
                    </template>
                  </v-data-table>
                </template>
              </div>
            </div>
          </v-expand-transition>
        </div>
      </template>
    </v-card-text>

    <!-- デモ内容編集 -->
    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable v-model="demoEditor.show" inset>
        <DemoEdit
          :show="demoEditor.show"
          :observer="observer"
          :demoCode="demoEditor.demoCode"
          @commit="onDemoEdit"
          @error="demoEditor.show = false"
        >
          <template v-slot:close>
            <v-btn text color="warning" @click="demoEditor.show = false">閉じる</v-btn>
          </template>
        </DemoEdit>
      </v-bottom-sheet>
    </ValidationObserver>

    <!-- リース内容編集 -->
    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable v-model="leasePaymentEditor.show" inset>
        <LeasePaymentEdit
          :show="leasePaymentEditor.show"
          :observer="observer"
          :leasePaymentCode="leasePaymentEditor.leasePaymentCode"
          :viewStartState="leasePaymentEditor.viewStartState"
          @commit="onLeasePaymentEdit"
          @error="leasePaymentEditor.show = false"
        >
          <template v-slot:close>
            <v-btn text color="warning" @click="leasePaymentEditor.show = false">閉じる</v-btn>
          </template>
        </LeasePaymentEdit>
      </v-bottom-sheet>
    </ValidationObserver>
  </v-card>
</template>


<script>
// import SukesanRow from './SukesanRow';

import DemoEdit from '@/components/demos/Edit';
import LeasePaymentEdit from '@/components/leasePayments/Edit';

import { LeasePaymentStartStates, TeamMarkTypes } from '@/types';

export const AnalyseSaleTypes = {
  SystemNew: 1, // システム新規
  SystemReplace: 1 << 1, // システムリプレイス
  Sukesan: 1 << 2, // すけさん
  Rental: 1 << 3, // レンタル
  Web: 1 << 4, // Web
  Other: 1 << 5, // その他
  CorrectedTotal: 1 << 6, // 補正データ

  // 温浴・旅館
  // 宿泊売上
  Stay: 1 << 7,
  // 飲食売上
  EatingAndDrinking: 1 << 8,
  // 日帰り売上(温浴)
  DayStay: 1 << 9,
  // 併設(売店)売上
  Store: 1 << 10,
  // 売上戻り
  ReturnBack: 1 << 11,
  // 売上値引
  Discount: 1 << 12,

  // 宿プラス
  YadoPlus: 1 << 13,

  // 補正値を加味した小計
  ResultTotal: 1 << 14,

  Sum: 1 << 15, // その他
};

export default {
  components: {
    // SukesanRow,
    DemoEdit,
    LeasePaymentEdit,
  },

  props: {
    datas: {
      type: Array,
      default: () => [],
    },
    // すけさん売上見込み
    sukesanDatas: {
      type: Array,
      default: () => [],
    },
    // date: {
    //   type: Date,
    //   required: true,
    // },
    dates: {
      type: Array,
      default: () => [],
    },

    sectionType: {
      type: Number,
      default: () => null,
    },
    teamMarkType: {
      type: Number,
      default: () => null,
    },

    branchCode: {
      type: String,
      default: () => null,
    },
    teamCode: {
      type: String,
      default: () => null,
    },
    teams: {
      type: Array,
      default: () => [],
    },

    initializing: {
      type: Boolean,
      default: () => true,
    },

    hideSukesan: {
      type: Boolean,
      default: () => false,
    },

    hideDetailList: {
      type: Boolean,
      default: () => false,
    },

    dataTitles: {
      type: Object,
      default: () => ({
        quotasBeforeNow: false,
        quotas: true,
        prospectAmount2: true,
        prospectPercent2: true,
        amount: true,
        percent: true,
      }),
    },
  },
  computed: {
    AnalyseSaleTypes() {
      return AnalyseSaleTypes;
    },
    LeasePaymentStartStates() {
      return LeasePaymentStartStates;
    },

    // 指定月の次の月の1日
    nextMonth() {
      if (this.date == null) return null;
      return new Date(this.date.getFullYear(), this.date.getMonth() + 1, 1);
    },

    isteamMarkTypeLeader() {
      return (this.teamMarkType & TeamMarkTypes.Leader) != 0;
    },

    date() {
      if (this.dates.length == 0) return null;
      if (this.dates.length == 1) return this.dates[0];

      // 小さいほうを指定月とする
      if (this.$moment(this.dates[0]).isBefore(this.$moment(this.dates[1]))) {
        return this.dates[0];
      }

      return this.dates[1];
    },

    monthSpan() {
      if (this.dates.length == 0) return 0;
      if (this.dates.length == 1) return 1;
      //
      let months = Math.abs(this.$moment(this.dates[0]).diff(this.$moment(this.dates[1]), 'months'));

      // ２期間中なので開始月分を含ませる
      months += 1;

      return months;
    },

    hasTeams() {
      return this.teams.length > 0;
    },
    teamsValue() {
      if (this.hasTeams) {
        const teams = this.teams.map((a) => Object.assign({}, a));
        // FIXME フラグを与え使用するかの有無を設定すること
        teams.push({
          code: null,
          name: '当期累計',
          dataTitles: {
            quotasBeforeNow: true,
            quotas: false,
            prospectAmount2: false,
            prospectPercent2: false,
            amount: true,
            percent: true,
          },
        });

        return teams;
      }
      return [];
    },

    detailDatas() {
      return this.hasTeams
        ? this.teamsValue.map((a) => ({
            date: null,
            teamCode: a.code,
            teamName: a.name,
            branchCode: this.branchCode,
            //
            dataTitles: a.dataTitles != null ? a.dataTitles : this.dataTitles,
          }))
        : Array(this.monthSpan)
            .fill()
            .map((_, i) => ({
              date: this.getDate(i),
              teamCode: this.teamCode,
              teamName: null,
              branchCode: this.branchCode,
              dataTitles: this.dataTitles,
            }));
    },
  },

  data: () => ({
    tableTab: 0,

    expand1: false,
    expand2: false,

    loading: {
      step: 0,
      timeouts: [],
    },

    demoEditor: {
      show: false,
      demoCode: null,
    },

    leasePaymentEditor: {
      show: false,
      demoCode: null,
      viewStartState: 0,
    },
  }),

  methods: {
    onLoad() {
      for (let i = 0; i < 100; i++) {
        this.loading.timeouts.push(setTimeout(() => this.loading.step++, (Math.floor(Math.random() * 10 * 5) * 1000) / 2));
      }
    },

    getAnalyseSaleTypeLabel(val) {
      switch (val) {
        case AnalyseSaleTypes.SystemNew:
          return 'システム:新規';
        case AnalyseSaleTypes.SystemReplace:
          return 'システム:リプ';
        case AnalyseSaleTypes.Sukesan:
          return 'すけさん';
        case AnalyseSaleTypes.Rental:
          return 'レンタル';
        case AnalyseSaleTypes.Web:
          return 'WEB';
        case AnalyseSaleTypes.Other:
          return 'その他';

        case AnalyseSaleTypes.Stay:
          return '宿泊売上';
        case AnalyseSaleTypes.EatingAndDrinking:
          return '飲食売上';
        case AnalyseSaleTypes.DayStay:
          return '日帰り売上(温浴)';
        case AnalyseSaleTypes.Store:
          return '併設(売店)売上';
        case AnalyseSaleTypes.ReturnBack:
          return '売上戻り';
        case AnalyseSaleTypes.Discount:
          return '売上値引';

        case AnalyseSaleTypes.YadoPlus:
          return '宿ぷらす(業販)';

        default:
          return null;
      }
    },

    getRowStartLabel(demo) {
      // リース
      if (demo.saleType == 1) {
        // リース情報 取材導入情報
        // 事前審査OK
        let isOK = demo.leasePayment != null && demo.leasePayment.state == 1 << 2;
        // リース開始確認
        let isStartOK = demo.leasePayment != null && demo.leasePayment.isStarted;
        // 導入完了
        let isComplete = demo.hpCoverage != null && demo.hpCoverage.state == 1 << 2;
        //
        if (!isOK && !isStartOK && !isComplete) return '審査中';
        if (isOK && !isStartOK && !isComplete) return '導入待';
        if (isOK && !isStartOK && isComplete) return '導入済';
        if (isOK && isStartOK && isComplete) return '確認済';
      }
      // 現金
      if (demo.saleType == 1 << 2) {
        // リース情報 取材導入情報
        // 請求書発行済み
        let invoiced = demo.sale != null && demo.sale.invoiceDate != null;
        // 導入完了
        let isComplete = demo.hpCoverage != null && demo.hpCoverage.state == 1 << 2;

        //
        if (!invoiced && !isComplete) return '導入待';
        if (invoiced && !isComplete) return '導入待';
        if (!invoiced && isComplete) return '導入済';
        if (invoiced && isComplete) return '請求済';
      }

      return null;
    },

    onDemoEdit() {
      this.$emit('demoCommit');

      this.demoEditor.show = false;
      //
    },

    onLeasePaymentEdit() {
      console.log('viewer onLeasePaymentEdit');

      this.$emit('leasepaymentCommit');

      this.leasePaymentEditor.show = false;
    },
    analyseSukesanData(sukesanDatas, date, weekInMonth, branchCode, teamCode) {
      //
      if (branchCode != null) sukesanDatas = sukesanDatas.filter((a) => a.branchCode == branchCode);
      if (teamCode != null) sukesanDatas = sukesanDatas.filter((a) => a.teamCode == teamCode);
      // すけさん予算を取得するために年月のみでデータを取得させる
      if (weekInMonth != null) sukesanDatas = sukesanDatas.filter((a) => a.weekInMonth == weekInMonth);

      let sukesanData = sukesanDatas.filter((a) => a.year == date.getFullYear()).find((a) => a.month == date.getMonth() + 1);
      if (sukesanData == null) return {};

      return {
        charge: sukesanData.charge,
        quotaSukesan: sukesanData.quotaSukesan,
        quotaSukesanPer: sukesanData.quotaSukesanPer,
      };
    },

    isVisibled(type, param) {
      // 「リーダー支店」でなければ表示
      if (!this.isteamMarkTypeLeader) return true;

      // リーダー支店は売上があれば表示
      const data = this.analyseData(this.datas, this.date, type, this.branchCode, this.teamCode, null, this.sectionType);
      return data[param] > 0;
    },

    getDate(index) {
      if (this.date == null) return null;
      const moment = this.$moment(this.date).add(index, 'months');
      return moment.toDate();
    },

    dataTitlesCount(dataTitles) {
      let count = 0;
      Object.keys(dataTitles).forEach((key) => {
        if (dataTitles[key]) count++;
      });
      // if (this.dataTitles.quotasBeforeNow) count++;
      // if (this.dataTitles.quotas) count++;
      // if (this.dataTitles.prospectAmount2) count++;
      // if (this.dataTitles.prospectPercent2) count++;
      // if (this.dataTitles.amount) count++;
      // if (this.dataTitles.percent) count++;
      return count;
    },
  },
  watch: {
    initializing(val) {
      if (!val) {
        this.loading.timeouts.forEach((a) => clearTimeout(a));
        this.loading.step = 100;
      }
    },
  },
  mounted() {
    this.onLoad();
  },
};
</script>
<style scoped>
.divider {
  border-right: solid 1px #dddddd;
}
</style>