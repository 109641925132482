<template>
  <v-card flat>
    <v-card-title>
      <slot name="close"></slot>
      <v-spacer />
      デモ結果編集
      <v-spacer />
      <v-btn v-if="isOK" color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onCommit">登録</v-btn>
      <v-btn v-else color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onCommitNotOK">登録</v-btn>
    </v-card-title>

    <v-card-text :style="isOK ? 'height: 1200px' : ''">
      <XSheet :loading="initializing">
        <template v-slot="{ loaded }">
          <div v-if="loaded">
            <template v-if="apo">
              <ApoResult :apo="apo" />
              <v-divider />
            </template>

            <v-expansion-panels v-model="expansions">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.facilityName)">{{
                      isEmptyValue(creator.facilityName) ? '*顧客情報を登録してください' : '*顧客情報'
                    }}</XCheckLabel>
                    <span v-if="creator.facilityName != null" class="ml-4 success--text">
                      <span class="ml-2">{{ creator.facilityName }}</span>
                      <span class="ml-2">{{ creator.facilityPref.name }}</span>
                      <span class="ml-2">{{ creator.facilityAreaName }}</span>
                      <span class="ml-2">{{ creator.facilityTel }}</span>
                    </span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card flat>
                    <v-card-text>
                      <v-alert border="right" colored-border type="error" elevation="2">顧客情報の変更はできません</v-alert>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.date)">{{
                      isEmptyValue(creator.date) ? '*デモ実施日を入力してください' : '*デモ実施日'
                    }}</XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.date(creator.date)"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <XFieldSet label="デモ実施日">
                          <validation-provider v-slot="{ errors }" name="デモ実施日" rules="">
                            <DateTime label="デモ実施日" v-model="date" hideTime :error-messages="errors">
                              <template v-slot="{ click }"
                                ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on">{{ date | date }} </v-btn>
                              </template>
                            </DateTime>
                          </validation-provider>
                        </XFieldSet>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            date = null;
                            creator.date = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.date = date;
                            expansions++;
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.reportType)">{{
                      isEmptyValue(creator.reportType) ? '*報告区分を選択してください' : '*報告区分'
                    }}</XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.demoReportType(creator.reportType)"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="報告区分" rules="required">
                          <v-select
                            v-model="reportType"
                            :items="[
                              { text: $options.filters.demoReportType(1), value: 1 },
                              { text: $options.filters.demoReportType(1 << 1), value: 1 << 1 },
                              { text: $options.filters.demoReportType(1 << 2), value: 1 << 2 },
                              { text: $options.filters.demoReportType(1 << 3), value: 1 << 3 },
                              { text: $options.filters.demoReportType(1 << 4), value: 1 << 4 },

                              { text: $options.filters.demoReportType(1 << 9), value: 1 << 9 },
                            ]"
                            label="*報告区分"
                            :rules="[(v) => !!v || '報告区分 は必須です']"
                            :error-messages="errors"
                            required
                            outlined
                            dense
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            reportType = null;
                            creator.reportType = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.reportType = reportType;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.resultType)">{{
                      isEmptyValue(creator.resultType) ? '*デモ結果を選択してください' : '*デモ結果'
                    }}</XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.demoResultType(creator.resultType)"></span>
                    <span class="ml-4">{{ creator.demoSchedule | dateTime }}</span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="デモ結果" rules="required">
                          <v-select
                            v-model="resultType"
                            :items="[
                              { text: $options.filters.demoResultType(1), value: 1 },
                              { text: $options.filters.demoResultType(1 << 1), value: 1 << 1 },
                              { text: $options.filters.demoResultType(1 << 2), value: 1 << 2 },
                              { text: $options.filters.demoResultType(1 << 3), value: 1 << 3 },
                              { text: $options.filters.demoResultType(1 << 4), value: 1 << 4 },
                              { text: $options.filters.demoResultType(1 << 5), value: 1 << 5 },
                              { text: $options.filters.demoResultType(1 << 9), value: 1 << 9 },
                            ]"
                            label="*結果"
                            :rules="[(v) => !!v || '結果 は必須です']"
                            :error-messages="errors"
                            required
                            outlined
                            dense
                          />
                        </validation-provider>

                        <p class="text-caption">
                          <strong class="red--text">受注報告後のキャンセル</strong
                          >は、ここは「受注」のまま下記の<strong>受注後キャンセル</strong>を登録し、備考にキャンセル理由を記載するやり方でキャンセルを登録します
                        </p>

                        <template v-if="resultType == 1 << 9">
                          <p class="red--text">新しいデモ日程を登録してください。(必須)</p>
                          <XFieldSet label="*デモ予定日時">
                            <validation-provider v-slot="{ errors }" name="デモ予定日時" rules="">
                              <DateTime label="デモ予定日時" v-model="demoSchedule" :error-messages="errors">
                                <template v-slot="{ click }"
                                  ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on"
                                    >{{ demoSchedule | dateTime }}
                                  </v-btn>
                                </template>
                              </DateTime>
                            </validation-provider>
                          </XFieldSet>
                        </template>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            resultType = null;
                            creator.resultType = null;
                            creator.demoSchedule = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.resultType = resultType;
                            creator.demoSchedule = demoSchedule;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel
                      :value="!isEmptyValue(creator.branch) && !isEmptyValue(creator.demointer) && !isEmptyValue(creator.team)"
                      >{{
                        isEmptyValue(creator.branch) || isEmptyValue(creator.demointer) || isEmptyValue(creator.team)
                          ? '*デモ担当情報を登録してください'
                          : '*デモ担当'
                      }}</XCheckLabel
                    >
                    <span v-if="creator.branch != null && creator.demointer != null" class="ml-4 success--text">
                      <span v-if="creator.demointer != null" class="ml-2">{{ creator.demointer.name }}</span>
                      <span v-if="creator.branch != null" class="ml-2">{{ creator.branch.name }}</span>
                      <span v-if="creator.team != null" class="ml-2">{{ creator.team.name }}</span>
                    </span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col md="4">
                            <validation-provider v-slot="{ errors }" name="支店" rules="required">
                              <v-select
                                v-model="branch"
                                :items="dataContext.shitens"
                                label="*支店"
                                item-text="name"
                                item-value="id"
                                :rules="[(v) => !!v || '支店 は必須です']"
                                :error-messages="errors"
                                return-object
                                required
                                dense
                              />
                            </validation-provider>
                          </v-col>
                          <v-col md="4">
                            <validation-provider v-slot="{ errors }" name="デモ担当" rules="required">
                              <v-select
                                v-model="demointer"
                                :items="staffs"
                                label="*デモ担当"
                                item-text="name"
                                item-value="id"
                                :rules="[(v) => !!v || 'デモ担当 は必須です']"
                                :error-messages="errors"
                                return-object
                                required
                                outlined
                                dense
                              />
                            </validation-provider>
                          </v-col>
                          <v-col md="4">
                            <validation-provider v-slot="{ errors }" name="チーム" rules="required">
                              <v-select
                                v-model="team"
                                :items="teams"
                                label="*チーム"
                                item-text="name"
                                item-value="id"
                                :rules="[(v) => !!v || 'チーム は必須です']"
                                :error-messages="errors"
                                return-object
                                required
                                outlined
                                dense
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            branch = null;
                            apointer = null;
                            team = null;
                            creator.branch = null;
                            creator.demointer = null;
                            creator.team = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.branch = branch;
                            creator.demointer = demointer;
                            creator.team = team;
                            expansions++;
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="isOK">
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="creator.systemTypes.length > 0">{{
                      creator.length == 0 ? '*システムを登録してください' : '*システム'
                    }}</XCheckLabel>
                    <span class="ml-4 success--text">
                      {{ creator.systemTypes.map((a) => $options.filters.demoSystemType(a)) | implode(',') }}
                    </span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="システム" rules="required">
                          <v-select
                            v-model="systemTypes"
                            :items="[
                              { text: $options.filters.demoSystemType(1 << 1), value: 1 << 1 },
                              { text: $options.filters.demoSystemType(1 << 2), value: 1 << 2 },
                              { text: $options.filters.demoSystemType(1 << 3), value: 1 << 3 },
                              { text: $options.filters.demoSystemType(1 << 4), value: 1 << 4 },
                              { text: $options.filters.demoSystemType(1 << 6), value: 1 << 6 },
                              { text: $options.filters.demoSystemType(1 << 7), value: 1 << 7 },
                              { text: $options.filters.demoSystemType(1 << 8), value: 1 << 8 },
                            ]"
                            label="*システム"
                            :rules="[(v) => !!v || 'システムは必須です']"
                            :error-messages="errors"
                            multiple
                            required
                            outlined
                            dense
                            style="width: 300px"
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            systemTypes = [];
                            creator.systemTypes = [];
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.systemTypes = systemTypes;
                            $nextTick(() => {
                              expansions++;
                            });
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="isOK">
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.contractType)">{{
                      isEmptyValue(creator.contractType) ? '*受注区分を登録してください' : '*受注区分'
                    }}</XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.demoContractType(creator.contractType)"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="受注区分" rules="required">
                          <v-select
                            v-model="contractType"
                            :items="[
                              { text: $options.filters.demoContractType(1), value: 1 },
                              { text: $options.filters.demoContractType(1 << 1), value: 1 << 1 },
                              { text: $options.filters.demoContractType(1 << 2), value: 1 << 2 },
                              { text: $options.filters.demoContractType(1 << 3), value: 1 << 3 },
                            ]"
                            label="*受注区分"
                            :rules="[(v) => !!v || '受注区分は必須です']"
                            :error-messages="errors"
                            required
                            outlined
                            dense
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            contractType = null;
                            creator.contractType = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid && contractType != 0"
                          @click="
                            creator.contractType = contractType;
                            expansions++;
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="isOK">
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.saleType) || !isEmptyValue(creator.sales)">
                      *売上区分,受注金額を登録してください
                    </XCheckLabel>
                    <span v-if="!isEmptyValue(creator.saleType)" class="ml-4 success--text"
                      >*売上区分:{{ creator.saleType | demoSaleType }}
                    </span>
                    <span v-if="creator.term > 0" class="ml-4 success--text"> 期間:{{ creator.term | monthToYear }} </span>
                    <span v-if="saleType == 1" class="ml-4 success--text"> 暫定料率:{{ creator.rate }}&#37; </span>
                    <span v-if="!isEmptyValue(creator.sales)" class="ml-4 success--text"> 受注金額:{{ creator.sales | priceJP }} </span>
                    <span v-if="!isEmptyValue(creator.residualBonds)" class="ml-4 success--text"
                      >残債:{{ creator.residualBonds | priceJP }}
                    </span>
                    <span v-if="!isEmptyValue(creator.underwritingBonds)" class="ml-4 success--text"
                      >他社買取:{{ creator.underwritingBonds | priceJP }}
                    </span>
                    <span v-if="!isEmptyValue(creator.complimentaryBonds)" class="ml-4 success--text"
                      >サービス品:{{ creator.complimentaryBonds | priceJP }}
                    </span>
                    <span v-if="!isEmptyValue(creator.salesRecognitionScheduleDate)" class="ml-4 success--text"
                      >売上計上予定月:{{ creator.salesRecognitionScheduleDate | dateMonth }}
                    </span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <div class="d-flex justify-space-around">
                          <validation-provider v-slot="{ errors }" name="売上区分" rules="required">
                            <v-select
                              v-model="saleType"
                              :items="[
                                { text: $options.filters.demoSaleType(1), value: 1 },
                                { text: $options.filters.demoSaleType(1 << 1), value: 1 << 1 },
                                { text: $options.filters.demoSaleType(1 << 2), value: 1 << 2 },
                                { text: $options.filters.demoSaleType(1 << 3), value: 1 << 3 },
                                { text: $options.filters.demoSaleType(1 << 4), value: 1 << 4 },
                              ]"
                              label="*売上区分"
                              :rules="[(v) => !!v || '売上区分は必須です']"
                              :error-messages="errors"
                              required
                              outlined
                              dense
                            />
                          </validation-provider>

                          <validation-provider v-slot="{ errors }" name="期間" rules="required">
                            <v-select
                              class="ml-2"
                              v-model="term"
                              :items="[
                                { text: $options.filters.monthToYear(12), value: 12 },
                                { text: $options.filters.monthToYear(60), value: 60 },
                              ]"
                              label="*期間"
                              :rules="[(v) => !!v || '期間は必須です']"
                              :error-messages="errors"
                              required
                              outlined
                              dense
                            />
                          </validation-provider>

                          <template v-if="saleType == 1">
                            <validation-provider v-slot="{ errors }" name="暫定料率" rules="required">
                              <v-combobox
                                class="ml-2"
                                v-model.number="rate"
                                :items="['1.83', '1.82']"
                                label="*暫定料率"
                                :rules="[(v) => !!v || '暫定料率 は必須です']"
                                counter
                                :maxlength="5"
                                :error-messages="errors"
                                required
                                dense
                              ></v-combobox>
                            </validation-provider>
                          </template>
                        </div>

                        <XFieldSet class="mb-4" label="金額">
                          <v-card flat>
                            <v-card-title>
                              <validation-provider
                                v-slot="{ errors }"
                                name="受注金額"
                                :rules="(saleType & (1 | (1 << 1) | (1 << 2))) != 0 ? 'required' : ''"
                              >
                                <v-text-field
                                  type="number"
                                  v-model.number="sales"
                                  label="受注金額(税抜)"
                                  hint="リース・レンタル・現金の場合必須です"
                                  persistent-hint
                                  counter
                                  :maxlength="10"
                                  :error-messages="errors"
                                  clearable
                                  dense
                                  suffix="円"
                                />
                              </validation-provider>

                              <v-spacer />

                              <validation-provider v-slot="{ errors }" name="残債" rules="">
                                <v-text-field
                                  class="ml-4"
                                  type="number"
                                  v-model.number="residualBonds"
                                  label="残債"
                                  counter
                                  :maxlength="10"
                                  :error-messages="errors"
                                  clearable
                                  dense
                                  suffix="円"
                                />
                              </validation-provider>

                              <validation-provider v-slot="{ errors }" name="他社買取" rules="">
                                <v-text-field
                                  class="ml-4"
                                  type="number"
                                  v-model.number="underwritingBonds"
                                  label="他社買取"
                                  counter
                                  :maxlength="10"
                                  :error-messages="errors"
                                  clearable
                                  dense
                                  suffix="円"
                                />
                              </validation-provider>

                              <validation-provider v-slot="{ errors }" name="サービス品" rules="">
                                <v-text-field
                                  class="ml-4"
                                  type="number"
                                  v-model.number="complimentaryBonds"
                                  label="サービス品"
                                  counter
                                  :maxlength="10"
                                  :error-messages="errors"
                                  clearable
                                  dense
                                  suffix="円"
                                />
                              </validation-provider>
                            </v-card-title>
                            <v-card-actions>
                              <p class="h4-text">
                                <strong>見込み金額</strong> :{{
                                  ($options.filters.calcLeaseAmount(sales, saleType, rate, term) -
                                    residualBonds -
                                    underwritingBonds -
                                    complimentaryBonds)
                                    | priceJP
                                }}
                              </p>
                            </v-card-actions>
                          </v-card>
                        </XFieldSet>

                        <p>
                          <XFieldSet label="売上計上予定月">
                            <validation-provider v-slot="{ errors }" name="売上計上予定月" rules="">
                              <DateTime
                                label="売上計上予定月"
                                v-model="salesRecognitionScheduleDate"
                                hideTime
                                month
                                :error-messages="errors"
                              >
                                <template v-slot="{ click }"
                                  ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on"
                                    >{{ salesRecognitionScheduleDate | dateMonth }}
                                  </v-btn>

                                  <v-btn icon @click="salesRecognitionScheduleDate = null"><v-icon v-text="'mdi-close'" /> </v-btn>
                                </template>
                              </DateTime>
                            </validation-provider>
                          </XFieldSet>
                        </p>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            saleType = null;
                            sales = null;
                            residualBonds = null;
                            underwritingBonds = null;
                            complimentaryBonds = null;

                            term = null;
                            rate = null;
                            salesRecognitionScheduleDate = null;

                            creator.saleType = null;
                            creator.sales = null;
                            creator.residualBonds = null;
                            creator.underwritingBonds = null;
                            creator.complimentaryBonds = null;
                            creator.term = null;
                            creator.rate = null;
                            creator.salesRecognitionScheduleDate = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            //
                            creator.saleType = saleType;
                            creator.sales = sales;
                            creator.residualBonds = residualBonds;
                            creator.underwritingBonds = underwritingBonds;
                            creator.complimentaryBonds = complimentaryBonds;
                            creator.term = term;
                            creator.rate = rate;
                            creator.salesRecognitionScheduleDate = salesRecognitionScheduleDate;

                            expansions++;
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="isOK">
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.initialCost)">{{
                      isEmptyValue(creator.initialCost) ? '初期費用(税抜)を登録してください' : '初期費用(税抜)'
                    }}</XCheckLabel>
                    <span v-if="creator.initialCost != null" class="ml-4 success--text">
                      <span class="ml-2">{{ creator.initialCost | priceJP }}</span>
                    </span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="初期費用" rules="max:100">
                          <v-text-field
                            type="number"
                            v-model.number="initialCost"
                            label="初期費用(税抜)"
                            counter
                            :maxlength="10"
                            :error-messages="errors"
                            clearable
                            dense
                            suffix="円"
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            initialCost = null;
                            creator.initialCost = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.initialCost = initialCost;
                            expansions++;
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="isOK">
                <v-expansion-panel-header>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>オプション:</v-list-item-title>
                      <v-list-item-content>
                        <v-row>
                          <v-col cols="6" md="4">
                            <span class="ml-2" v-if="creator.meta.cloud != null"
                              >クラウド:<strong v-text="$options.filters.metaCloud(creator.meta.cloud.id)"
                            /></span>
                          </v-col>
                          <v-col cols="6" md="4">
                            <span class="ml-2" v-if="creator.meta.shuukyaku_support != null"
                              >集客サポート:<strong v-text="$options.filters.metaShuukyaku_support(creator.meta.shuukyaku_support.id)" />
                            </span>
                          </v-col>
                          <v-col cols="6" md="4">
                            <span class="ml-2" v-if="creator.meta.kaikei_support != null"
                              >会計サポート:<strong v-text="$options.filters.metaKaikei_support(creator.meta.kaikei_support.id)"
                            /></span>
                          </v-col>
                          <v-col cols="6" md="4">
                            <span class="ml-2" v-if="creator.meta.katyao != null"
                              >かっちゃお:<strong v-text="$options.filters.metaKatyao(creator.meta.katyao.id)"
                            /></span>
                          </v-col>
                          <v-col cols="6" md="4">
                            <span class="ml-2" v-if="creator.meta.hp != null"
                              >HP:<strong v-text="$options.filters.metaHp(creator.meta.hp.id)"
                            /></span>
                          </v-col>
                          <v-col cols="6" md="4">
                            <span class="ml-2" v-if="creator.meta.yokoku_hp != null"
                              >予告HP:<strong v-text="$options.filters.metaYokoku_hp(creator.meta.yokoku_hp.id)"
                            /></span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6" md="4">
                            <span
                              class="ml-2"
                              v-if="Array.isArray(creator.meta.asunaroPlusType) && creator.meta.asunaroPlusType.length > 0"
                            >
                              あすなろプラス:
                              <strong class="ml-2" v-for="(asunaroPlusType, index) of creator.meta.asunaroPlusType" :key="index">
                                <!-- Fixme implodeでよいが、記述が長くなったため以下でトライ -->
                                <template v-if="index != 0">,</template>
                                {{ asunaroPlusType | asunaroPlusType }}
                              </strong>
                            </span>
                          </v-col>

                          <v-col cols="6" md="4">
                            <span class="ml-2" v-if="Array.isArray(creator.meta.yadoPlusType) && creator.meta.yadoPlusType.length > 0">
                              宿プラス+:
                              <strong class="ml-2" v-for="(yadoPlusType, index) of creator.meta.yadoPlusType" :key="index">
                                <!-- Fixme implodeでよいが、記述が長くなったため以下でトライ -->
                                <template v-if="index != 0">,</template>
                                {{ yadoPlusType | yadoPlusType }}
                              </strong>
                            </span>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item-content>
                  </v-list-item>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col md="4">
                            <validation-provider v-slot="{ errors }" name="クラウド" rules="">
                              <v-select
                                v-model="meta.cloud"
                                :items="dataContext.clouds"
                                label="クラウド"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                return-object
                                dense
                              />
                            </validation-provider>
                          </v-col>
                          <v-col md="4">
                            <validation-provider v-slot="{ errors }" name="集客サポート" rules="">
                              <v-select
                                v-model="meta.shuukyaku_support"
                                :items="dataContext.shuukyaku_supports"
                                label="集客サポート"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                return-object
                                dense
                              />
                            </validation-provider>
                          </v-col>
                          <v-col md="4">
                            <validation-provider v-slot="{ errors }" name="会計サポート" rules="">
                              <v-select
                                v-model="meta.kaikei_support"
                                :items="dataContext.kaikei_supports"
                                label="会計サポート"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                return-object
                                dense
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col md="4">
                            <validation-provider v-slot="{ errors }" name="かっちゃお" rules="">
                              <v-select
                                v-model="meta.katyao"
                                :items="dataContext.katyaos"
                                label="かっちゃお"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                return-object
                                dense
                              />
                            </validation-provider>
                          </v-col>
                          <v-col md="4">
                            <validation-provider v-slot="{ errors }" name="HP" rules="">
                              <v-select
                                v-model="meta.hp"
                                :items="dataContext.hps"
                                label="HP"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                return-object
                                dense
                              />
                            </validation-provider>
                          </v-col>
                          <v-col md="4">
                            <validation-provider v-slot="{ errors }" name="予告HP" rules="">
                              <v-select
                                v-model="meta.yokoku_hp"
                                :items="dataContext.yokoku_hps"
                                label="予告HP"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                return-object
                                dense
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <validation-provider v-slot="{ errors }" name="あすなろプラス" rules="">
                              <v-select
                                v-model="meta.asunaroPlusType"
                                :items="[
                                  { text: $options.filters.asunaroPlusType(AsunaroPlusTypes.Order), value: AsunaroPlusTypes.Order },
                                  { text: $options.filters.asunaroPlusType(AsunaroPlusTypes.Kitchen), value: AsunaroPlusTypes.Kitchen },
                                ]"
                                label="あすなろプラス"
                                multiple
                                :error-messages="errors"
                                dense
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col>
                            <validation-provider v-slot="{ errors }" name="宿プラス+" rules="">
                              <v-select
                                v-model="meta.yadoPlusType"
                                :items="[
                                  { text: $options.filters.yadoPlusType(YadoPlusTypes.Ryosenkikaku), value: YadoPlusTypes.Ryosenkikaku },
                                ]"
                                label="宿プラス+"
                                multiple
                                :error-messages="errors"
                                dense
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            meta.cloud = null;
                            meta.shuukyaku_support = null;
                            meta.kaikei_support = null;
                            meta.katyao = null;
                            meta.katyao = null;
                            meta.hp = null;
                            meta.yadoPlus = [];
                            creator.meta.cloud = null;
                            creator.meta.shuukyaku_support = null;
                            creator.meta.kaikei_support = null;
                            creator.meta.katyao = null;
                            creator.meta.hp = null;
                            creator.meta.yokoku_hp = null;
                            creator.meta.asunaroPlusType = [];
                            creator.meta.yadoPlusType = [];
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.meta = meta;
                            $nextTick(() => {
                              expansions++;
                            });
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <XCheckLabel :value="!isEmptyValue(creator.memo)">{{
                    isEmptyValue(creator.memo) ? '備考を登録してください' : '備考'
                  }}</XCheckLabel>
                  <span class="d-inline-block text-truncate ml-2" style="max-width: 120px">{{ creator.memo }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="備考" rules="max:1000">
                          <v-textarea
                            v-model="memo"
                            label="備考"
                            rows="3"
                            counter
                            :maxlength="1000"
                            :error-messages="errors"
                            clearable
                            outlined
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            memo = null;
                            creator.memo = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.memo = memo;
                            expansions = null;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.cancelDate)">受注後キャンセル</XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.date(creator.cancelDate)"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p>
                    このキャンセル日設定は<strong class="red--text">受注で報告したが、その後キャンセルとなった</strong
                    >場合、この日付を設定しキャンセルとしてください。<br />
                    キャンセル理由は備考に記入してください。
                  </p>
                  <p class="text-caption">例：受注で報告し、リース審査など後続の手続きが進んだが、諸事情ありキャンセルになった</p>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <XFieldSet label="キャンセル日">
                          <validation-provider v-slot="{ errors }" name="キャンセル日" rules="">
                            <DateTime label="キャンセル日" v-model="cancelDate" hideTime :error-messages="errors">
                              <template v-slot="{ click }"
                                ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on">{{ cancelDate | date }} </v-btn>
                              </template>
                            </DateTime>
                          </validation-provider>
                        </XFieldSet>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            cancelDate = null;
                            creator.cancelDate = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.cancelDate = cancelDate;
                            expansions = null;
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </template>
      </XSheet>
    </v-card-text>
    <v-card-actions>
      <span class="text-caption">{{ demoCode }}</span>
    </v-card-actions>
  </v-card>
</template>

<script>
import ApoResult from '@/components/apos/Result';

import { AsunaroPlusTypes, YadoPlusTypes } from '@/types';

export default {
  components: {
    ApoResult,
  },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },

    observer: {
      type: Object,
      required: true,
    },

    demoCode: {
      type: String,
      required: true,
    },
  },
  computed: {
    AsunaroPlusTypes() {
      return AsunaroPlusTypes;
    },
    YadoPlusTypes() {
      return YadoPlusTypes;
    },
    form() {
      return {
        loading: () => this.creator.loading,
        disabled: () => this.creator.loading || this.initializing,
        canSubmit: () => !this.creator.loading && !this.initializing && !this.observer.invalid && this.canCommit,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
    isOK() {
      return this.creator.resultType == 1 << 1;
    },
    isRedemo() {
      return this.creator.resultType == 1 << 9;
    },
    canCommit() {
      if (this.isOK) {
        if (this.isEmptyValue(this.creator.date)) return false;
        if (this.isEmptyValue(this.creator.facilityName)) return false;
        if (this.isEmptyValue(this.creator.reportType)) return false;
        if (this.isEmptyValue(this.creator.resultType)) return false;
        if (this.isEmptyValue(this.creator.systemTypes.length)) return false;
        if (this.isEmptyValue(this.creator.contractType)) return false;
        if (this.isEmptyValue(this.creator.saleType)) return false;
        if (this.isEmptyValue(this.creator.term)) return false;

        if (this.isEmptyValue(this.creator.branch)) return false;
        if (this.isEmptyValue(this.creator.demointer)) return false;
        if (this.isEmptyValue(this.creator.team)) return false;

        // リース・レンタル・現金の場合金額必須
        if (this.isEmptyValue(this.creator.sales)) {
          if (this.creator.saleType == 1) return false; // リース
          if (this.creator.saleType == 1 << 1) return false; // レンタル
          if (this.creator.saleType == 1 << 2) return false; // 現金
        }
      } else {
        if (this.isEmptyValue(this.creator.date)) return false;
        if (this.isEmptyValue(this.creator.reportType)) return false;
        if (this.isEmptyValue(this.creator.resultType)) return false;
        // if (this.isEmptyValue(this.creator.systemTypes.length)) return false;
        if (this.isEmptyValue(this.creator.branch)) return false;
        if (this.isEmptyValue(this.creator.demointer)) return false;
        if (this.isEmptyValue(this.creator.team)) return false;

        // if (this.isEmptyValue(this.creator.memo)) return false;
      }

      if (this.isRedemo) {
        if (this.isEmptyValue(this.creator.demoSchedule)) return false;
      }

      return true;
    },

    staffs() {
      let staffs = this.dataContext.staffs;
      if (this.branch != null) {
        staffs = staffs.filter((a) => a.branchCode == this.branch.id);
      }
      return staffs;
    },

    teams() {
      if (this.branch != null) return this.branch.teams;
      return [];
    },
  },
  data: () => ({
    //
    initializing: true,

    facilities: [],
    apo: null,
    //
    expansions: null, // multipleではいので単体

    date: null,
    cancelDate: null,
    salesRecognitionScheduleDate: null,

    facilityName: null,
    facilityAreaName: null,
    facilityTel: null,
    facilityPref: null,

    facility: {
      loading: false,
      code: null,
      name: null,
      pref: {},
    },

    //
    reportType: null,
    resultType: null,
    systemTypes: [],
    contractType: null,
    saleType: null,
    term: null,
    rate: null,

    branch: null,
    demointer: null,

    sales: null,
    residualBonds: null,
    underwritingBonds: null,
    complimentaryBonds: null,

    initialCost: null,

    meta: {
      cloud: null,
      shuukyaku_support: null,
      kaikei_support: null,
      katyao: null,
      hp: null,
      yokoku_hp: null,
      asunaroPlusType: [], // enum:multiple
      yadoPlusType: [], // enum:multiple
    },

    memo: null,

    // 再デモ用
    demoSchedule: null,

    creator: {
      loading: false,

      date: null,
      cancelDate: null,

      //
      facilityName: null,
      facilityAreaName: null,
      facilityTel: null,
      facilityPref: null,

      facility: {
        code: null,
        name: null,
        pref: {},
      },

      //
      reportType: null,
      resultType: null,
      systemTypes: [],
      contractType: null,
      saleType: null,
      term: null,
      rate: null,

      branch: null,
      demointer: null,

      salesRecognitionScheduleDate: null,
      sales: null,
      residualBonds: null,
      underwritingBonds: null,
      complimentaryBonds: null,

      initialCost: null,

      meta: {
        cloud: null,
        shuukyaku_support: null,
        kaikei_support: null,
        katyao: null,
        hp: null,
        yokoku_hp: null,
        asunaroPlusType: [], // enum:multiple
        yadoPlusType: [], // enum:multiple
      },

      memo: null,

      // 再デモ用
      demoSchedule: null,
    },
  }),
  methods: {
    init() {
      //
      this.expansions = null;
      //
      this.apo = null;

      this.date = null;

      this.cancelDate = null;
      this.salesRecognitionScheduleDate = null;

      this.facilityName = null;
      this.facilityAreaName = null;
      this.facilityTel = null;
      this.facilityPref = null;

      this.facility = {
        loading: false,
        code: null,
        name: null,
        pref: {},
      };

      //
      this.reportType = null;
      this.resultType = null;
      this.systemTypes = [];
      this.contractType = null;
      this.saleType = null;
      this.term = null;
      this.rate = null;

      this.branch = null;
      this.demointer = null;

      this.sales = null;

      this.residualBonds = null;
      this.underwritingBonds = null;
      this.complimentaryBonds = null;

      this.initialCost = null;
      this.memo = null;

      // 再デモ用
      this.demoSchedule = null;

      this.meta = {
        cloud: null,
        shuukyaku_support: null,
        kaikei_support: null,
        katyao: null,
        hp: null,
        yokoku_hp: null,
        asunaroPlusType: [], // enum:multiple
        yadoPlusType: [], // enum:multiple
      };

      this.creator = {
        date: null,

        cancelDate: null,
        salesRecognitionScheduleDate: null,

        //
        facilityName: null,
        facilityAreaName: null,
        facilityTel: null,
        facilityPref: null,

        facility: {
          code: null,
          name: null,
          pref: {},
        },

        //
        reportType: null,
        resultType: null,
        systemTypes: [],
        contractType: null,
        saleType: null,
        term: null,
        rate: null,

        branch: null,
        demointer: null,

        sales: null,

        residualBonds: null,
        underwritingBonds: null,
        complimentaryBonds: null,

        initialCost: null,

        meta: {
          cloud: null,
          shuukyaku_support: null,
          kaikei_support: null,
          katyao: null,
          hp: null,
          yokoku_hp: null,
          asunaroPlusType: [], // enum:multiple
          yadoPlusType: [], // enum:multiple
        },

        memo: null,

        // 再デモ用
        demoSchedule: null,
      };
    },
    //
    onLoaded() {
      //
      this.initializing = true;
      //
      this.init();

      //

      //
      this.get('demo', { demoCode: this.demoCode })
        .then((success) => {
          //
          let data = success.data;

          let demointer = { id: null, name: null };
          if (data.demointer != null) {
            demointer.id = data.demointer.code;
            demointer.name = data.demointer.name;
          }

          let branch = { id: null, name: null };
          if (data.branch != null) {
            branch.id = data.branch.code;
            branch.name = data.branch.name;
            branch.teams = data.branch.teams;
          }

          let team = { id: null, name: null };
          if (data.team != null) {
            team.id = data.team.code;
            team.name = data.team.name;
          }

          let meta = {
            cloud: { id: data.meta.cloud },
            shuukyaku_support: { id: data.meta.shuukyaku_support },
            kaikei_support: { id: data.meta.kaikei_support },
            katyao: { id: data.meta.katyao },
            hp: { id: data.meta.hp },
            yokoku_hp: { id: data.meta.yokoku_hp },

            asunaroPlusType: this.convertBit2Ar(data.meta.asunaroPlusType), // enum:multiple
            yadoPlusType: this.convertBit2Ar(data.meta.yadoPlusType), // enum:multiple
          };

          let apo = data.apo;

          this.apo = apo;

          this.date = data.date;
          this.cancelDate = data.cancelDate;
          this.salesRecognitionScheduleDate = data.salesRecognitionScheduleDate;

          this.facilityName = data.facility.name;
          this.facilityAreaName = data.facility.areaName;
          this.facilityTel = data.facility.tel;
          this.facilityPref = data.facility.pref;
          this.facility = data.facility;
          this.reportType = data.reportType;
          this.resultType = data.resultType;
          this.systemTypes = data.systemTypes.map((a) => a.value);
          this.contractType = data.contractType;
          this.saleType = data.saleType;
          this.term = data.term;
          this.rate = data.rate;

          this.branch = branch;
          this.demointer = demointer;
          this.team = team;
          this.sales = data.sales;
          this.residualBonds = data.residualBonds;
          this.underwritingBonds = data.underwritingBonds;
          this.complimentaryBonds = data.complimentaryBonds;
          this.initialCost = data.initialCost;
          this.meta = Object.assign({}, meta);
          this.memo = data.memo;

          // 再デモ用
          if (apo != null) {
            this.demoSchedule = apo.demoSchedule;
            this.creator.demoSchedule = apo.demoSchedule;
          }
          //
          this.creator.date = data.date;
          this.creator.cancelDate = data.cancelDate;
          this.creator.salesRecognitionScheduleDate = data.salesRecognitionScheduleDate;

          this.creator.facilityName = data.facility.name;
          this.creator.facilityAreaName = data.facility.areaName;
          this.creator.facilityTel = data.facility.tel;
          this.creator.facilityPref = data.facility.pref;
          this.creator.facility = data.facility;
          this.creator.reportType = data.reportType;
          this.creator.resultType = data.resultType;
          this.creator.systemTypes = data.systemTypes.map((a) => a.value);
          this.creator.contractType = data.contractType;
          this.creator.saleType = data.saleType;
          this.creator.term = data.term;
          this.creator.rate = data.rate;

          this.creator.branch = data.branch;
          this.creator.demointer = data.demointer;
          this.creator.team = data.team;
          this.creator.sales = data.sales;
          this.creator.residualBonds = data.residualBonds;
          this.creator.underwritingBonds = data.underwritingBonds;
          this.creator.complimentaryBonds = data.complimentaryBonds;
          this.creator.initialCost = data.initialCost;
          this.creator.meta = Object.assign({}, meta);
          this.creator.memo = data.memo;

          this.initializing = false;
        })
        .catch(() => {
          this.showError('データ取得に失敗しました');
          this.$emit('error');
        });
      //
    },

    /**
     * フォーム決定イベント
     */
    async onCommit() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('登録してよろしいですか？')) return;
      //

      let request = this.creator;
      request.demoCode = this.demoCode;
      request.date = this.convertDate(this.creator.date);
      request.cancelDate = this.convertDate(this.creator.cancelDate);
      request.salesRecognitionScheduleDate = this.convertDate(this.creator.salesRecognitionScheduleDate);

      request.demoSchedule = this.convertDate(this.creator.demoSchedule);
      // num->enum(s)
      request.meta.asunaroPlusType = this.convertAr2Bit(this.creator.meta.asunaroPlusType);
      request.meta.yadoPlusType = this.convertAr2Bit(this.creator.meta.yadoPlusType);
      // nullable
      request.initialCost = this.convertNumber(request.initialCost);
      request.rate = this.convertNumber(request.rate);
      request.sales = this.convertNumber(request.sales);
      request.residualBonds = this.convertNumber(request.residualBonds);
      request.underwritingBonds = this.convertNumber(request.underwritingBonds);
      request.complimentaryBonds = this.convertNumber(request.complimentaryBonds);

      //
      this.put('demo', request)
        .then((success) => {
          //
          this.showSuccessSnackbar(success);
          //
          this.$emit('commit', this.demoCode);
        })
        .catch((error) => this.showErrorSnackbar(error));
    },

    /**
     * フォーム決定イベント
     */
    async onCommitNotOK() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('登録してよろしいですか？')) return;
      //

      let request = this.creator;
      request.demoCode = this.demoCode;
      request.date = this.convertDate(this.creator.date);
      request.cancelDate = this.convertDate(this.creator.cancelDate);
      request.salesRecognitionScheduleDate = this.convertDate(this.creator.salesRecognitionScheduleDate);

      request.demoSchedule = this.convertDate(this.creator.demoSchedule);
      //
      this.put('demo/notOK', request)
        .then((success) => {
          //
          this.showSuccessSnackbar(success);
          //
          this.$emit('commit', this.demoCode);
        })
        .catch((error) => this.showErrorSnackbar(error));
    },

    onCancelClicked() {
      this.$emit('cancel');
    },

    onFacilityTelInput() {
      if (this.facilityTel.length <= 2) return;

      this.facility.loading = true;
      this.get('search/facility/tel', { tel: this.facilityTel }).then((success) => {
        //
        let data = success.data;
        this.facilities = data;

        this.facility.loading = false;
      });
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {},
};
</script>