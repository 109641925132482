<template>
  <XLoader
    :loading="loading"
    :type="loaderType"
    :color="loaderColor"
    :min-width="minWidth"
    :max-width="maxWidth"
    :min-height="minHeight"
    :max-height="maxHeight"
  >
    <template v-slot="{ loaded }">
      <slot :loaded="loaded"></slot>
    </template>
  </XLoader>
</template>


<script>
export default {
  components: {},

  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },

    loaderType: {
      type: String,
      default: () => 'circular',
    },

    loaderColor: {
      type: String,
      default: () => 'primary',
    },

    minWidth: {
      type: [Number, String],
      default: () => 50,
    },

    maxWidth: {
      type: [Number, String],
      default: () => null,
    },

    minHeight: {
      type: [Number, String],
      default: () => 50,
    },
    maxHeight: {
      type: [Number, String],
      default: () => null,
    },
  },
  computed: {
    attrs() {
      return {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      };
    },
  },
  data: () => ({}),
  methods: {
    // #region events
    onLoaded() {},

    //#endregion
  },
  mounted() {
    this.onLoaded();
  },
};
</script>
